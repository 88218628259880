import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div>
      <footer className="footer">
        <div className="row text-center footer__primary p-5">
          <div className="col-lg-4 col-sm-6 col-xs-6">
            <ul className="footer__primary-list ps-0">
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/">
                  Home
                </Link>
              </li>
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/coverage">
                  Coverage
                </Link>
              </li>
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/services">
                  Services
                </Link>
              </li>
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/partner-center">
                  Partner Center
                </Link>
              </li>
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/cases">
                  Cases
                </Link>
              </li>
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/testimonials">
                  Testimonials
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-sm-6 col-xs-6">
            <ul className="footer__primary-list ps-0">
              
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/events">
                  Events
                </Link>
              </li>
              {/* <li className="footer__link-margin">
                <Link className="footer__link link" to="/blog">
                  Blog
                </Link>
              </li> */}
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/blogFile">
                  Blogs
                </Link>
              </li>
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/contact">
                  Contact Us
                </Link>
              </li>
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/global-nsoc">
                  Global NSOC
                </Link>
              </li>
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/sitemap">
                Sitemap
                </Link>
              </li>
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/about-us">
                About Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-sm-12 col-xs-12 footer__media container">
            <div className="justify-content-center py-3">
              <a
                className="footer__link my-3"
                href="https://twitter.com/BITSBYTEGLOBAL"
              >
                <i className="footer__link-fontsize fab fa-twitter px-3"></i>
              </a>
              <a
                className="footer__link my-3"
                href="https://www.facebook.com/bitsandbyteglobal"
              >
                <i className="footer__link-fontsize fab fa-facebook-f px-3"></i>
              </a>
              <a
                className="footer__link my-3"
                href="https://www.linkedin.com/company/bitsandbyteglobal/"
              >
                <i className="footer__link-fontsize fab fa-linkedin-in px-3"></i>
              </a>
              <a
                className="footer__link my-3"
                href="https://www.instagram.com/bitsandbyteglobal/"
              >
                <i className="footer__link-fontsize fab fa-instagram px-3"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="container__waves">
          <div>
            <svg
              className="container__waves-waves"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28"
              preserveAspectRatio="none"
              shapeRendering="auto"
            >
              <defs>
                <path
                  id="gentle-wave"
                  d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                />
              </defs>
              <g className="parallax">
                <use
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="0"
                  fill="rgba(255,255,255,0.7"
                />
                <use
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="3"
                  fill="rgba(255,255,255,0.5)"
                />
                <use
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="5"
                  fill="rgba(255,255,255,0.3)"
                />
                <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
              </g>
            </svg>
          </div>
        </div>

        <div className="row footer__secondary my-3">
          <div className="col-lg-4 footer__secondary-legal py-3 my-3">
            <Link
              className="footer__link text-start ms-4  px-2 link"
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
            |
            <Link className="footer__link px-2 link" to="/policy-gtc-aup">
              Legal
            </Link>
          </div>
          <div className="col-lg-8 footer__secondary-copyright my-3">
            <p className="pt-3 mx-2">
              &copy; 2023 INTELLIGENT QUOTING TECHNOLOGY S.L. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
