import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Nigeria() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet Connectivity and Telecommunication in Nigeria - inte-QT</title>
        <meta
          name="title"
          content="Internet Connectivity and Telecommunication in Nigeria - inte-QT"
        />
        <meta name="description" content="Explore the internet and telecommunication landscape in Nigeria with inte-QT services, including 4G, 5G, and broadband solutions." />
        <meta
          name="keywords"
          content="internet in Nigeria, telecommunications in Nigeria, broadband solutions Nigeria, 5G services Nigeria, mobile internet Nigeria, Nigeria infrastructure, telecom market growth, Nigeria submarine cables, Nigeria internet providers"
        />
        <link
          rel="canonical"
          href="https://www.inte-qt.com/coverage/nigeria"
        />
      </Helmet>
      <img
        src="https://i.imgur.com/sAoqVlf.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - The current population of Nigeria is{' '}
              <strong>215,203,368*</strong>
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Nigeria is bordered to
              the north by Niger, to the east by Chad and Cameroon, to the south
              by the Gulf of Guinea of the Atlantic Ocean, and to the west by
              Benin. Nigeria is not only large in area—larger than the U.S.
              state of Texas—but also Africa's most populous country.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - Hausa was an official language
              of the northern states from 1951 to 1967. It is the most widely
              spoken language, although English is the official language of
              Nigeria. In addition to English, Hausa, Yoruba, Igbo, Fula, and
              English Creole are widely spoken. Many of the languages exist in
              written form.
            </li>
            <li>
              <strong>CURRENCY</strong> - The Nigerian naira (NGN) is the
              official currency of the Federal Republic of Nigeria.
            </li>
            <li>
              <strong>INDUSTRIES</strong> - The services sector - consisting of
              telecommunications, financial, and other services - is the largest
              sector in Nigeria, although it saw a slight decline, accounting
              for about 54.39% of GDP in Q4 2020.The largest industries which
              drive the country's economy and account for the bulk of its annual
              GDP are its petroleum, tourism, agriculture, and mining
              industries.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Lagos</li>
                <li>Kano</li>
                <li>Ibadan</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>WEATHER</strong> - Nigeria has a tropical climate with
                variable rainy and dry seasons, depending on location. It is hot
                and wet most of the year in the southeast but dry in the
                southwest and farther inland. A savanna climate, with marked wet
                and dry seasons, prevails in the north and west, while a steppe
                climate with little precipitation is found in the far north.
              </p>
            </li>
            <li>
              <p>
                <strong>NATIONAL SPORT</strong> - The Nigeria national football
                team competes regularly for international titles and many
                Nigerian footballers compete in Europe, particularly in England.
                Nigeria at the FIFA World Cup.
              </p>
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/4c843cf20657ffc830940f3e719c2b02/raw/19227509dd01ee8376f7d22f780db0e1f5ba2771/nigeria-flag.svg"
              alt="Nigeria flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            Nigeria, officially known as the Federal Republic of Nigeria, is a
            diverse West African nation. Often referred to as the "Giant of Africa",
            Nigeria is the seventh-most populous country globally, with over
            200 million people.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4034910.7009228664!2d6.4334882342906266!3d9.032489545414377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0baf7da48d0d%3A0x99a8fe4168c50bc8!2sNigeria!5e0!3m2!1sen!2ses!4v1652175620046!5m2!1sen!2ses"
            width="95%"
            title="Nigeria-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <h4 className="mt-4">Tourism in Nigeria*</h4>
          <p>
            Nigeria's tourism sector thrives with diverse cultural events, rich
            ethnic groups, and breathtaking natural attractions such as rainforests,
            savannahs, and waterfalls.
          </p>

          <p>
            Nigeria tourism statistics for 2019 was 1,471,000,000.00, a 25.59%
            decline from 2018. Nigeria tourism statistics for 2018 was
            1,977,000,000.00, a 24.4% decline from 2017. Nigeria tourism
            statistics for 2017 was 2,615,000,000.00, a 140.35% increase from
            2016.
          </p>

          <h4 className="mt-4">Main Ports and Airports in Nigeria</h4>

          <p>
            Nigeria has 32 airports, with 26 operated by the Federal
            Airports Authority of Nigeria (FAAN), and five international airports
            connecting Nigeria to the world.
          </p>

          <table className="table">
            <thead>
              <tr>
                <th>IATA</th>
                <th>Name</th>
                <th>City</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>LOS</td>
                <td>Murtala Muhammed International Airport</td>
                <td>Lagos</td>
              </tr>
              <tr>
                <td>ABV</td>
                <td>Nnamdi Azikiwe International Airport</td>
                <td>Abuja</td>
              </tr>
              <tr>
                <td>PHC</td>
                <td>Port Harcourt International Airport</td>
                <td>Port Harcourt</td>
              </tr>
              <tr>
                <td>KAN</td>
                <td>Mallam Aminu International Airport</td>
                <td>Kano</td>
              </tr>
            </tbody>
          </table>

          <p>
            Nigeria's Ports, including Apapa and Tin Can in Lagos, Onne Port in
            Rivers State, Warri Port, and Calabar Port, are central to the country's
            international trade.
          </p>
          <ul>
            <li>LAGOS PORT COMPLEX</li>
            <li>TIN CAN ISLAND PORT</li>
            <li>CALABAR PORT</li>
            <li>RIVERS PORT COMPLEX</li>
            <li>DELTA PORT</li>
            <li>ONNE PORT</li>
          </ul>

          <h4 className="mt-5">Typical Nigerian Cuisine</h4>
          <p>
            Jollof rice is Nigeria's iconic national dish, prepared with rice,
            tomato, onion, and spices. It's a favorite throughout the country.
          </p>

          <h4 className="mt-5">Employment in Nigeria</h4>
          <p>
            Nigeria's employment rate has fluctuated, with the highest at 93.60%
            in Q4 2014 and the lowest at 66.70% in Q4 2020.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables in Nigeria</h4>
        <a
          href="https://www.submarinecablemap.com/country/nigeria"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/gFJBGJc.png"
            alt="submarine cables"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <li>2Africa</li>
          <li>Nigeria Cameroon Submarine Cable System</li>
          <li>Equiano</li>
          <li>West Africa Cable System</li>
          <li>MainOne</li>
          <li>Africa Coast to Europe</li>
          <li>Glo-1</li>
          <li>SAT-3/WESC</li>
        </ol>

        <h4 className="my-4">Mobile and Landline Internet Access in Nigeria*</h4>
        <p>
          As of 2021, mobile internet users in Nigeria reached over 101.7 million,
          and the country's telecom sector is poised for further growth, with increased
          mobile broadband and 5G adoption.
        </p>

        <h4 className="my-4">Wireless Network Expansion</h4>
        <p>
          Nigeria's telecom industry is embracing 5G technology, which will improve
          mobile services, support IoT, and enhance overall connectivity across the
          country.
        </p>

        <h4 className="my-4">Our Internet Services in Nigeria</h4>

        <p>
          Inte-QT partners with 8 infrastructure-owning providers in Nigeria to deliver
          high-performance internet solutions, including DIA, Business Broadband, and 4G/5G LTE.
        </p>

        <p>
          Our team offers a range of services in Benin, Agbara, and Lagos with 24/7 support
          from our NSOC team.
        </p>

        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins text-center"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a
          <mark>Commercial Offer in 2 - 3 working days.</mark> Our Global Ops
          team is capable to deliver services in major cities (Benin and Lagos)
          in 3 to 4 weeks and the rest of the cities in 5 - 6 weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.worldometers.info/world-population/nigeria-population/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.britannica.com/place/Nigeria"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.globalmonitor.us/product/nigeria-telecommunication-market-report"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Nigeria;
