import React, { useEffect } from 'react';
import './AboutUs.scss';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import AboutUsGallery from '../../components/AboutUsGallery/AboutUsGallery';
import CarouselIndu from '../../components/CarouselIndu/CarouselIndu';
import { Helmet } from 'react-helmet';

export default function AboutUs() {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="aboutUs">
      <Helmet>
        <title>About Us - inte-QT</title>
        <meta name="title" content="About Us - inte-QT" />
        <meta property="og:title" content="About Us - inte-QT" />
        <meta property="twitter:title" content="About Us - inte-QT" data-react-helmet="True" />
        <meta name="description" content="A little bit of information about who we are and what we do." />
        <meta property="og:description" content="A little bit of information about who we are and what we do." />
        <meta property="twitter:description" content="A little bit of information about who we are and what we do." data-react-helmet="True" />
        <link rel="canonical" href="https://www.inte-qt.com/about-us" />
        <meta property="og:url" content="https://www.inte-qt.com/about-us" />
        <meta property="twitter:url" content="https://www.inte-qt.com/about-us" />
      </Helmet>

      <img 
        src="https://i.imgur.com/Rgam7cI.jpg" 
        alt="inte-QT team" 
        className="banner-img banner-img__black" 
      />
      
      <h1 className="banner-img__header text-center">
        About inte-QT
      </h1>
      
      <div className="aboutUs__box" align="justify">
        <div className="b_read">
          <h2>Our Mission</h2>
          <p>"inte-QT" is derived from "integrity" and "equity," two fundamental principles that underpin our business philosophy. We believe that this new name encapsulates our dedication to honesty, fairness, and quality, which have always been at the heart of our partnership.</p>
          
          <h2>What We Do</h2>
          <p>At inte-QT, our mission is to empower Service Providers (Internet, Telco, Cloud, and SDWAN) and System Integrators with the best-in-class access service, global internet connectivity, and managed services across the globe. This name represents our dedication to providing cutting-edge technology solutions to simplify quoting processes and enhance your experience.</p>
          
          <h2>Our Global Presence</h2>
          <p>Headquartered in Madrid, Spain, we follow the best practices and certified processes with our team members experienced in Telecommunications. Our Global 24X7 Network & Security Operations Center (NSOC) is operational, where our teams manage technical solutions, project management, and global multi-layer support.</p>

          <p className="my-5">Our Supplier Management team has extensive experience in the Internet & Managed Service markets across the globe. We are vendor-agnostic, and our Partner Center program is designed to upgrade our partner network in every corner of the world.</p>
        </div>
      </div>

      <AboutUsGallery />
      
      <h2 className="center-header center-header__title mx-auto text-center">We Help Not One, But Many Industries</h2>
      <CarouselIndu />
      
      <GetInTouch />
    </div>
  );
}
