import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Bermuda() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Bermuda - Reliable and Fast Connectivity | inte-QT</title>
        <meta
          name="title"
          content="Internet in Bermuda - Reliable and Fast Connectivity | inte-QT"
        />
        <meta
          name="description"
          content="Explore reliable internet connectivity in Bermuda, with fast fibre-optic services, submarine cables, and top internet providers. Learn more about Bermuda's digital landscape."
        />
        <meta
          name="keywords"
          content="Internet in Bermuda, Bermuda fibre internet, Bermuda submarine cables, Bermuda internet providers, fast internet in Bermuda, Bermuda connectivity"
        />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/bermuda" />
      </Helmet>

      <img
        src="https://i.imgur.com/XY3pfTX.jpg"
        alt="Bermuda internet connectivity"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - The current population of Bermuda in
              2022 is <strong>61,939*</strong>.
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Located off the east
              coast of the United States, it is situated around 1,770 km (1,100
              mi) northeast of Miami, Florida and 1,350 km (840 mi) south of
              Halifax, Nova Scotia, west of Portugal, northwest of Brazil, 1,759
              km (1,093 mi) north of Cuba and north-northeast of Puerto Rico.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - The predominant language is
              Bermudian English, a fusion of various accents and influences. Spanish is also spoken, though by a small population.
            </li>
            <li>
              <strong>CURRENCY</strong> - The Bermuda Dollar is used, which is pegged to the U.S. dollar at a 1:1 rate.
            </li>
            <li>
              <strong>INDUSTRIES</strong> - International Business, Tourism, and Light Manufacturing.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Saint George</li>
                <li>Hamilton City</li>
                <li>Stovel Bay, Pembroke</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>WEATHER</strong> - Bermuda enjoys a semitropical climate with clear skies, sunny days, and mild temperatures.
              </p>
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/5cd1e74b93cb6dadd77c903633f887f1/raw/8b565516602b3d4d43de88d6f3076d89038e1843/bermuda-flag.svg"
              alt="Bermuda flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>

        <article>
          <h2>Bermuda - Overview</h2>
          <p>
            Bermuda is a self-governing British overseas territory located in the North Atlantic Ocean. The archipelago consists of 7 main islands and over 170 smaller islets, famous for its pink sand beaches.
          </p>

          <h3>Location and Geography</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d107894.98148513412!2d-64.83643972296025!3d32.31942447999867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8a2d139e8668b0a5%3A0x3cdffdc72c99b8bc!2sBermuda!5e0!3m2!1sen!2ses!4v1651138164390!5m2!1sen!2ses"
            width="95%"
            title="Bermuda Map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>

          <h4 className="mt-4">Tourism</h4>
          <p>
            Bermuda attracts over 650,000 visitors annually, with the majority coming from the United States.
          </p>

          <h4 className="mt-4">Main Ports / Airports</h4>
          <p>
            L.F. Wade International Airport is the sole airport in Bermuda. Additionally, there are three cruise ship ports: Royal Naval Dockyard, Hamilton Port, and St. George's Port.
          </p>

          <h4 className="mt-5">National Sport</h4>
          <p>
            Cricket is the national sport of Bermuda, widely enjoyed by locals.
          </p>

          <h4 className="mt-5">Typical Food</h4>
          <p>
            Bermuda fish chowder, made from fish stock, vegetables, and bacon fat, is considered a national dish.
          </p>

          <h4>Employment</h4>
          <p>
            Bermuda boasts a dynamic job market with opportunities across various sectors, including international business, tourism, and light manufacturing. The island's employment opportunities are supported by a well-educated workforce and strong economic foundations.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/bermuda"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/RrLNMI7.png"
            alt="Bermuda submarine cables"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <li>Caribbean-Bermuda U.S. (CBUS)</li>
          <li>Challenger Bermuda-1 (CB-1)</li>
          <li>Gemini Bermuda</li>
          <li>GlobeNet</li>
        </ol>

        <h4 className="my-4">
          ACCESS TO LANDLINE AND MOBILE PHONE INTERNET
        </h4>
        <p>
          Bermuda’s telecommunications system is among the best in the world, offering high-quality services for phone, fax, internet, and wireless connectivity.
        </p>

        <h4 className="my-4">Internet Providers</h4>
        <p>
          Bermuda has 5 major internet providers, ensuring widespread coverage. The country enjoys access to fast internet with speeds of up to 500 Mbps.
        </p>

        <strong>
          <p>
            inte-QT provides 1G internet connectivity to top-tier clients in Bermuda with guaranteed service.
          </p>
          <p>
            Our network solutions team ensures 24/7 monitoring and traffic shaping for optimal performance in Bermuda's key cities.
          </p>
        </strong>

        <h4 className="my-4">Our Capabilities</h4>

        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p className="CoverageFormat__tag CoverageFormat__margins text-center" align="justify">
          With our Global Business Solutions team, we provide commercial offers in 2-3 working days and deliver services in 15 to 20 working days.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.britannica.com/place/Bermuda"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.frommers.com/destinations/bermuda/planning-a-trip/when-to-go"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.gotobermuda.com/article/bermuda-boasts-reliable-wi-fi-across-the-island#:~:text=The%20island%20has%20the%20fastest,no%20worry%20about%20spotty%20service"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Bermuda;
