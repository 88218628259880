import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { app } from '../../firebase';
import DinamicLink from '../DinamicLink/DinamicLink';
import './BlogComponent.scss';

const BlogComponent = () => {
  const [blogData, setBlogData] = useState([]);
  const db = getDatabase(app);

  const renderHTML = (htmlString) => {
    return { __html: htmlString };  
  };

  const fetchLatestBlogs = async () => {
    try {
      const blogsRef = ref(db, 'blogs');
      const snapshot = await get(blogsRef);
      if (snapshot.exists()) {
        const blogsList = [];
        snapshot.forEach((childSnapshot) => {
          const blog = childSnapshot.val();
          blogsList.push({
            id: childSnapshot.key,
            ...blog,
          });
        });

        blogsList.sort((a, b) => {
          const dateA = new Date(a.date); 
          const dateB = new Date(b.date); 
          return dateB - dateA; 
        });

        setBlogData(blogsList.slice(0, 3)); 
      } else {
        console.log('No blogs found');
      }
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  useEffect(() => {
    fetchLatestBlogs();
  }, []);

  const isImageUrl = (url) => {
    return url.match(/\.(jpeg|jpg|gif|png|webp)$/i);
  };

  const isVideoUrl = (url) => {
    return url.match(/\.(mp4|webm|ogg)$/i) || url.includes('youtube.com') || url.includes('vimeo.com');
  };

  const renderMedia = (url) => {
    if (isImageUrl(url)) {
      return <img src={url} alt="Blog media" className="blog__img" />;
    } else if (isVideoUrl(url)) {
      return <video src={url} className="blog__img" controls />;
    } else {
      return <p>Unsupported media format</p>;
    }
  };

  return (
    <div className="blogComponent">
      <div className="container">
        <h1 className="text-center">Blogs</h1>

        <div className="blogComponent__list">
          {blogData.length > 0 ? (
            blogData.map((blog) => (
              <div key={blog.id} className="blogComponent__box">
                <div className="blogComponent__imgbox">
                  {blog.image1 && renderMedia(blog.image1)} 
                </div>
                <div className="blogComponent__details">
                  <p className="blogComponent__tags">
                    {blog.tags && blog.tags.split(',').map((tag, index) => (
                      <span key={index} className="blue mx-2">{tag.trim()}</span>
                    ))}
                  </p>
                  <h3 className="blogComponent__title">{blog.title}</h3>
                  <p className="blogComponent__author">
                    By <a href={blog.authorLink} className="blue">{blog.author}</a> on {blog.date}
                  </p>

                  <div className="blogComponent__introduction" dangerouslySetInnerHTML={renderHTML(blog.introduction)} />

                  <div className="text-end">
                    <DinamicLink url="/blogFile" name="Read more"/>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No blogs available yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogComponent;
