import React from 'react';
import './EventsComponent.scss';
import DinamicLink from '../DinamicLink/DinamicLink';

export default function EventsComponent() {
  return (
    <div className="news container-fluid">
      <div className="row text-center my-5">
      <div className="col-lg-3 col-md-8 col-12 card m-auto">
          <img src="https://i.imgur.com/XAx622Y.jpg" alt="2" className="news__img p-2 mt-3"/>
          <div className="card-body">
            <h3 className="card-title">Capacity Europe 2024</h3>
            <p className="card-text">15 - 17 October 2024  <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">InterContinental London - The 02</span></p>
            <div className="text-end">
              <DinamicLink url="/events" name="Read more"/>
            </div>
          </div>
        </div>  
      <div className="col-lg-3 col-md-8 col-12 card m-auto">
          <img src="https://i.imgur.com/IJBhBsd.jpg" alt="2" className="news__img p-2 mt-3"/>
          <div className="card-body">
            <h3 className="card-title">ITW Africa And Data Cloud Africa 2024</h3>
            <p className="card-text">10 - 12 September 2024<br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Kenya</span></p>
            <div className="text-end">
              <DinamicLink url="/events" name="Read more"/>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-8 col-12 card m-auto">
          <img src="https://i.imgur.com/43lN9Po.jpg" alt="2" className="news__img p-2 mt-3"/>
          <div className="card-body">
            <h3 className="card-title">Aslan 2024</h3>
            <p className="card-text">17 - 18 April 2024  <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Palacio de Congresos de IFEMA</span></p>
            <div className="text-end">
              <DinamicLink url="/events" name="Read more"/>
            </div>
          </div>
        </div>
       


      </div>
    </div>
  );
}
