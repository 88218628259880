import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Mozambique() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet Services and Coverage in Mozambique - inte-QT</title>
        <meta
          name="title"
          content="Internet Services and Coverage in Mozambique - inte-QT"
        />
        <meta
          name="description"
          content="Explore internet connectivity and broadband solutions in Mozambique. Discover available internet providers, mobile services, and submarine cable infrastructure."
        />
        <meta
          name="keywords"
          content="internet in Mozambique, broadband Mozambique, internet services Mozambique, mobile internet Mozambique, submarine cables Mozambique, internet providers Mozambique"
        />
        <link
          rel="canonical"
          href="https://www.inte-qt.com/coverage/mozambique"
        />
      </Helmet>

      <img
        src="https://i.imgur.com/0nJcdAY.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - In the long-term, the Mozambique
              Population is projected to trend around 32.05 Million in 2021 and{' '}
              <strong>32.90 Million*</strong> in 2022, according to our
              econometric models.
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Mozambique borders{' '}
              <strong>
                Tanzania, Malawi, Zambia, Zimbabwe, South Africa, and Eswatini
              </strong>
              . Its long Indian Ocean coastline of 2,500 kilometers faces east
              to Madagascar.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - Portuguese is the country's
              official language, but it is only spoken by around half of the
              population. The other most spoken primary languages in Mozambique,
              include: Makhuwa, Changana, Nyanja, Ndau, Sena, Chwabo, and Tswa.
            </li>
            <li>
              <strong>CURRENCY</strong> - Mozambican metical The metical is the
              currency of Mozambique, abbreviated with the symbol MZN or MT. It
              is nominally divided into 100 centavos. The name metical comes
              from Arabic مثقال, a unit of weight and an alternative name for
              the gold dinar coin that was used throughout much of Africa until
              the 19th century.
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Aluminum, Coal, Petroleum Products,
              Chemicals (fertilizer, soap, paints), Cement, Asbestos, Glass,
              Textiles, Tobacco, Food processing, Beverages.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Matola, 1.616.267* population</li>
                <li>Maputo, 1.101.170* population</li>
                <li>Nampula, 743.125* population</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>WEATHER</strong> - Mozambique has a
                <strong>tropical to sub-tropical</strong> climate and
                experiences two seasons: a cool and dry season from April to
                September and a hot and humid season between October and March.
                Temperatures are warmer near the coast and southern lowland
                regions compared to the higher, inland regions.
              </p>
            </li>
            <li>
              <p>
                <strong>EMPLOYMENT</strong> - Employers, total (% of total
                employment) (modeled ILO estimate) in Mozambique was reported at
                2.491 % in 2020, according to the World Bank collection of
                development indicators, compiled from officially recognized
                sources.
              </p>
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/33346827618f5264eb8092378f7cc886/raw/2b774ef5a71df83f27b9aaf42882634e25da6dc8/mozambique-flag.svg"
              alt="Mozambique flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>

        <article>
          <p>
            Mozambique, a scenic country in southeastern Africa, is rich in
            natural resources, biologically and culturally diverse, with a
            tropical climate.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7745571.310902386!2d31.312295239100607!3d-18.573261404275097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18d4aceae6fd4ac5%3A0x12bbbfb9ae16a115!2sMozambique!5e0!3m2!1sen!2ses!4v1648814378083!5m2!1sen!2ses"
            width="95%"
            title="mozambique-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>

          <h4 className="mt-4">Tourism</h4>
          <p>
            International tourism in Mozambique was reported at 2,033,000
            arrivals in 2019. Despite this, Mozambique has fewer tourists
            compared to neighboring countries like South Africa. The tourism
            sector is recovering post-civil war, but growth remains limited due
            to inadequate marketing and tour operator presence.
          </p>

          <h4 className="mt-4">Main Ports / Airports</h4>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">IATA</th>
                <th scope="col">Name</th>
                <th scope="col">City</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>MPM</td>
                <td>Maputo Airport</td>
                <td>Maputo</td>
              </tr>
              <tr>
                <td>APL</td>
                <td>Nampula Airport</td>
                <td>Nampula</td>
              </tr>
              <tr>
                <td>POL</td>
                <td>Pemba Airport</td>
                <td>Pemba / Porto Amelia</td>
              </tr>
              <tr>
                <td>BEW</td>
                <td>Beira Airport</td>
                <td>Beira</td>
              </tr>
            </tbody>
          </table>

          <p>
            Mozambique's key ports include Maputo, Beira, and Nacala. Smaller
            ports are located in Gaza, Inhambane, Quelimane, and Pemba.
          </p>

          <h4 className="mt-5">National Sport</h4>
          <p>
            Football (soccer) is Mozambique's favourite sport, with the famous
            Eusebio, known as the “Black Panther,” representing the country on
            the global stage.
          </p>

          <h4 className="mt-5">Typical Food</h4>
          <p>
            Xima (pronounced shima) is a staple food made from corn flour and
            a key part of Mozambican cuisine.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/mozambique"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/okZoBA5.png"
            alt="submarine cables"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <li>2Africa </li>

          <li>Eastern Africa Submarine System (EASSy) </li>

          <li>SEACOM/Tata TGN-Eurasia </li>
        </ol>

        <h4 className="my-4">
          ACCESS TO LANDLINE AND MOBILE PHONE ACCESS TO INTERNET*
        </h4>
        <p>
          Mozambique was one of the first countries in the region to introduce
          telecom reform, enabling the growth of mobile internet and broadband.
        </p>

        <h4 className="my-4">Internet Providers*</h4>
        <p>
          As of January 2020, Mozambique had 5.36 million internet users.
        </p>

        <h4 className="my-4">Our Capabilities</h4>
        <p>
          For mobile internet speed, the country has an average download
          speed of 16.03 Mbps and upload speed of 10.56 Mbps.
        </p>

        <p>
          inte-QT collaborates with local providers to offer a range of
          internet solutions, including business broadband, dedicated lines, and
          wireless connectivity.
        </p>

        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins text-center"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a{' '}
          <mark>Commercial Offer in 2-3 working days.</mark> Our Global Ops team
          is capable to deliver services in major cities (Maputo and Matola)
          country in 2 to 3 weeks and rest of the cities in 4- 5 weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.worldbank.org/en/country/mozambique/overview#1"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.budde.com.au/Research/Mozambique-Telecoms-Mobile-and-Broadband-Statistics-and-Analyses"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.britannica.com/place/Mozambique"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Mozambique;
