import React, { useEffect } from 'react';
import BigBanner from '../../components/BigBanner/BigBanner';
import CarouselIndu from '../../components/CarouselIndu/CarouselIndu.jsx';
import TestimonialCarousel from '../../components/TestimonialCarousel/TestimonialCarousel';
import { Helmet } from 'react-helmet';
import Typewriter from '../../components/Typewriter/Typewriter';
import Inteqt from '../../components/Inteqt/Inteqt';
import EventsComponent from '../../components/EventsComponent/EventsComponent';
import OurServices from '../../components/OurServices/OurServices';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import PartnerBenefits from '../../components/PartnerBenefits/PartnerBenefits';
import DinamicLink from '../../components/DinamicLink/DinamicLink';
import GlobalNsocComponent from '../../components/GlobalNsocComponent/GlobalNsocComponent';
import GemsSection from '../../components/GemsSection/GemsSection';
import BlogComponent from '../../components/BlogComponent/BlogComponent';

export default function Home() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="home">
      <Helmet>
        {/* <title>
          You Global Access Enabler in 190 countries - inte-QT
        </title>
        <meta name="title" content="You Global Access Enabler in 190 countries - inte-QT" />
        <meta
          name="description"
          content="Internet access in more than 190 countries around the world offering a variety of solutions."
        /> */}

    <title>inte-QT | Managed L3 Internet Global Services</title>
    <meta
      name="title"
      content="inte-QT | Managed L3 Internet Global Services"
    />
    <meta
      name="description"
      content="Internet access in more than 190 countries around the world offering a variety of solutions. Dedicated Lines, Business Broadband and Wireless Connect."
    />
    <link rel="canonical" href="https://www.inte-qt.com/" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.inte-qt.com/" />
    <meta
      property="og:title"
      content="inte-QT | Managed L3 Internet Global Services"
    />
    <meta
      property="og:description"
      content="Internet access in more than 190 countries around the world offering a variety of solutions. Dedicated Lines, Business Broadband and Wireless Connect."
    />
    <meta property="og:image" content="https://i.imgur.com/fgarNxn.png" />
    <meta property="og:site_name" content="inte-QT" />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://www.bitsnbyteglobal.com/" />
    <meta
      property="twitter:title"
      content="inte-QT | Managed L3 Internet Global Services"
    />
    <meta
      property="twitter:description"
      content="Internet access in more than 190 countries around the world offering a variety of solutions. Dedicated Lines, Business Broadband and Wireless Connect."
    />
    <meta property="twitter:image" content="https://i.imgur.com/fgarNxn.png" />
    <meta name="twitter:image:alt" content="Company Logo" />
      </Helmet>
{/*       <h1 style={{ display: 'none' }}> */}
{/*         You Global Access Enabler in 190+ countries - inte-QT */}
{/*       </h1> */}
      <BigBanner />

      <div>
        <h2 className="mx-auto text-center center-header pt-5 mt-5">
          Our Services
        </h2>
        <OurServices />
      </div>

      <div>
        <Typewriter />
      </div>
      <div>
  <a href="https://portal.inte-qt.com/auth/login/" target="_blank" rel="noopener noreferrer">
    <h2 className="mx-auto text-center center-header pt-5 mt-5">
      Aeta: Revolutionizing Connectivity
    </h2>
  </a>


        <Inteqt />
      </div>

      <div>
        <h2 className="text-center mx-auto center-header center-header__title">
          Why become our partner?
        </h2>
        <PartnerBenefits />
        <div className="text-center">
          <i className="fas fa-chevron-down my-4 partnerBenefits__arrow"></i>
          <DinamicLink url="/partner-center" name="Read more" />
        </div>
      </div>

      <div>
        <GlobalNsocComponent />
      </div>

      <div>
        <h2 className="text-center center-header mx-auto center-header__title">
          We help not One, but many Industries.
        </h2>
        <CarouselIndu />
      </div>

      <div>
        <h2 className="mx-auto text-center center-header pt-5 mt-5">
          G.E.M.S: Global Edge Managed Services
        </h2>
        <GemsSection />
      </div>

      <div>
        <EventsComponent />
      </div>

      <div>
        <BlogComponent />
      </div>

      <div>
        <TestimonialCarousel />
      </div>

      <GetInTouch />
    </div>
  );
}
