import React, { useEffect } from 'react';
import '../CoverageFormat.scss';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import { Helmet } from 'react-helmet';

function Austria() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Austria - inte-QT</title>
        <meta
          name="title"
          content="Internet in Austria - inte-QT"
        />
        <meta
          name="description"
          content="Austria has 10 internet providers with infrastructure, offering widespread access across the country. inte-QT partners with local providers to enable global access with 8 infrastructure-owning partners in Austria."
        />
        <meta
          name="keywords"
          content="internet in Austria, broadband providers, mobile networks, Austria internet access, Austria connectivity"
        />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/austria" />
      </Helmet>

      <img
        src="https://i.imgur.com/YrjxC8I.jpg"
        alt="Big banner representing Austria"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>Population:</strong> 9.08 million*
            </li>
            <li>
              <strong>Neighboring Countries:</strong> Czech Republic, Germany, Slovakia, Hungary, Slovenia, Italy, Switzerland, Liechtenstein
            </li>
            <li>
              <strong>Language Spoken:</strong> German (official), with Croatian, Slovenian, and Hungarian as regional languages.
            </li>
            <li>
              <strong>Currency:</strong> Euro (€)
            </li>
            <li>
              <strong>Industries:</strong> Food & luxury commodities, mechanical engineering, steel, chemicals, vehicle manufacturing
            </li>
            <li>
              <strong>Employment Rate:</strong> 74% (as of Q3 2021)
            </li>
            <li>
              <strong>Top 3 Cities:</strong> Vienna, Graz, Linz
            </li>
            <li>
              <strong>Tourism:</strong> Over 31.9 million* visitors
            </li>
            <li>
              <strong>Weather:</strong> Temperate and alpine climate
            </li>
            <li>
              <strong>National Sport:</strong> Winter sports (alpine skiing, ice hockey), soccer, rugby
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://i.imgur.com/QcyNtcX.png"
              alt="Austria Flag"
              className="my-5"
              style={{ width: 150 }}
            />
          </div>
        </nav>

        <article>
          <h2>A Brief Overview of Austria</h2>
          <p>
            Austria is a parliamentary representative democracy with a population of over 1.9 million in its capital, Vienna. It ranks among the wealthiest nations globally, with a nominal GDP per capita of $46,972 (2018). Austria boasts a high standard of living and was ranked 18th on the Human Development Index in 2019.
          </p>

          <p>
            Austria has been a member of the United Nations since 1955, joined the European Union in 1995, and adopted the Euro in 1999. It is also a founding member of the OECD and signed the Schengen Agreement in 1995.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2750785.79354466!2d11.102982330416456!3d47.678316722159266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d079b259d2a7f%3A0x1012d47bdde4c1af!2sAustria!5e0!3m2!1sen!2ses!4v1643219487670!5m2!1sen!2ses"
            width="95%"
            title="Austria Map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>

          <h4>Main Ports and Airports</h4>
          <p>
            Austria is well-connected both nationally and internationally through major airports in Vienna, Salzburg, and Innsbruck.
          </p>
          <ul>
            <li>Salzburg Airport</li>
            <li>Vienna International Airport</li>
            <li>Graz Airport</li>
          </ul>

          <h4>Traditional Austrian Cuisine</h4>
          <ul>
            <li>Viennese Apfelstrudel</li>
            <li>Wiener Schnitzel</li>
            <li>Vienna Sausage</li>
            <li>Knödel</li>
            <li>Tafelspitz</li>
            <li>Tiroler Gröstl</li>
            <li>Käsespätzle</li>
            <li>Potato Gulasch</li>
          </ul>
        </article>
      </section>

      <div className="container">
        <h4>Connectivity Overview</h4>
        <table className="table table-striped table-responsive">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Millions</th>
              <th scope="col">Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Broadband users</td>
              <td>8.22 m</td>
              <td>95 %</td>
            </tr>
            <tr>
              <td>Landlines</td>
              <td>-</td>
              <td>118.61 per 100 inhabitants</td>
            </tr>
            <tr>
              <td>Mobile cellulars</td>
              <td>13.08 m</td>
              <td>144 %</td>
            </tr>
            <tr>
              <td>Access to internet</td>
              <td>8.03 m</td>
              <td>89 %</td>
            </tr>
          </tbody>
        </table>

        <h4>Internet Providers in Austria</h4>
        <p>
          As of 2020, 90% of Austrian households have internet access, and Austria's mobile connections reached 13.08 million by January 2021.
        </p>
        <p>
          Austria has an average internet download speed of 20.30 Mbps and an upload speed of 8.8 Mbps.
        </p>
        <p>
          The country is served by 10 internet providers, with inte-QT partnering with 8 of these providers to offer infrastructure and connectivity solutions.
        </p>

        <h5>Our Capabilities</h5>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Capabilities</th>
              <th scope="col">
                <i className="fas fa-check"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>Wireless Connect</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>SLA</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>DDoS Protection</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins"
          align="justify"
        >
          Contact us to receive a <mark>Commercial Offer in 1-2 working days</mark>. Our Global Ops team is ready to deliver services in major cities in 10 to 14 working days and other regions in 4 to 6 weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.worldometers.info/world-population/austria-population/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.austria.org/overview"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://tradingeconomics.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Austria;
