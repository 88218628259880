import React, { useEffect } from 'react';
import '../CoverageFormat.scss';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import { Helmet } from 'react-helmet';

function Ethiopia() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet Connectivity and Business Solutions in Ethiopia - inte-QT</title>
        <meta
          name="description"
          content="Learn about internet connectivity in Ethiopia, local providers, and how inte-QT can help with broadband, business solutions, and more."
        />
        <meta name="keywords" content="internet connectivity, Ethiopia, broadband, mobile, telecommunications, internet providers, Ethiopia business solutions" />
        <meta property="og:title" content="Internet Connectivity and Business Solutions in Ethiopia - inte-QT" />
        <meta property="og:description" content="Explore Ethiopia’s internet usage, providers, and how inte-QT offers global business solutions in the country." />
        <meta property="og:image" content="https://i.imgur.com/5CqSGjp.jpg" />
        <meta property="og:url" content="https://www.inte-qt.com/coverage/ethiopia" />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/ethiopia" />
      </Helmet>
      <img
        src="https://i.imgur.com/5CqSGjp.jpg"
        alt="Ethiopia internet connectivity and business landscape"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - 117.8 million*
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - It is bordered by the{' '}
              <strong>Sudan</strong> in the west, Somalia and Djibouti in the
              east, Eritrea in the north, and Kenya in the south.
              <ul>
                <li>It is the 10th largest country in Africa.</li>
                <li>The 2nd-most populous country in Africa after Nigeria.</li>
                <li>100 mts. Below sea level lowest pt.</li>
                <li>4620 mts the highest pt.. Ras Dashen.</li>
              </ul>
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - <strong>Amharic</strong> is the
              government's official language, and Oromo is widely spoken by over a third of the population.
            </li>
            <li>
              <strong>CURRENCY</strong> - <strong>The Ethiopian birr (ETB)</strong>.
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Food processing, textiles, leather, chemicals, and cement.
            </li>
            <li>
              <strong>EMPLOYMENT*</strong> - <strong>10.35%</strong> of total employment in industry (World Bank 2020).
            </li>
            <li>
              <strong>TOP 3 CITIES</strong> - Addis Ababa (Finfinne), Dire Dawa, Mek'ele.
            </li>
            <li>
              <strong>WEATHER</strong> - Ethiopia has a largely arid climate with variable precipitation.
            </li>
            <li>
              <strong>NATIONAL SPORT</strong> - <strong>Football</strong>.
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/273b46b3dc5f2b8b5c28d29c610ae7fb/raw/aed7f61b45f84619ccb81300278b5e919a97f354/ethiopia-flag.svg"
              alt="Ethiopian flag"
              className="my-5"
              style={{ width: 150 }}
            />
          </div>
        </nav>

        <article>
          <h2>Ethiopia: A Land of Opportunities and Connectivity</h2>
          <p>
            Ethiopia, located in the Horn of Africa, is a landlocked country with rich cultural heritage. It is strategically positioned to access international trade routes, making it an important regional hub.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7388883.609510792!2d36.526389358997605!3d8.06756548664737!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1635d0cedd6cfd2b%3A0x7bf6a67f5348c55a!2sEthiopia!5e0!3m2!1sen!2ses!4v1644315762590!5m2!1sen!2ses"
            width="95%"
            height="450"
            title="Ethiopia Map"
            className="CoverageFormat__margins"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>

          <h3 className="mt-4">Tourism and Economy</h3>
          <p>
            Ethiopia's rich history, including ancient sites like Lalibela and Aksum, attracts thousands of tourists each year. The country's economic landscape, fueled by industries like textiles and food processing, is growing rapidly.
          </p>

          <h3 className="mt-4">Connectivity and Internet Providers</h3>
          <p>
            Ethiopia's internet penetration is on the rise, with more than 23.96 million users reported in 2021. There are currently six local internet providers, and inte-QT partners with three key players to offer comprehensive services across the country.
          </p>

          <table className="table table-striped table-responsive">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Millions</th>
                <th scope="col">Percentage</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Broadband users</td>
                <td>21.5 m</td>
                <td>18.5 %</td>
              </tr>
              <tr>
                <td>Landlines</td>
                <td>21.1 m</td>
                <td>18.4 %</td>
              </tr>
              <tr>
                <td>Mobile cellulars</td>
                <td>44.77 m</td>
                <td>38.5 %</td>
              </tr>
              <tr>
                <td>Access to internet</td>
                <td>23.96 m</td>
                <td>20.6 %</td>
              </tr>
            </tbody>
          </table>

          <h4 className="my-4">Our Capabilities</h4>
          <table className="table table-striped mb-5">
            <tbody>
              <tr>
                <td>Dedicated Lines</td>
                <td>
                  <i className="fas fa-check"></i>
                </td>
              </tr>
              <tr>
                <td>Business Broadband</td>
                <td>
                  <i className="fas fa-check"></i>
                </td>
              </tr>
              <tr>
                <td>Wireless Connect</td>
                <td>
                  <i className="fas fa-check"></i>
                </td>
              </tr>
              <tr>
                <td>CPE / Router</td>
                <td>
                  <i className="fas fa-check"></i>
                </td>
              </tr>
              <tr>
                <td>GEMS</td>
                <td>
                  <i className="fas fa-check"></i>
                </td>
              </tr>
              <tr>
                <td>Diverse Gateway Solutions</td>
                <td>
                  <i className="fas fa-check"></i>
                </td>
              </tr>
              <tr>
                <td>DDoS</td>
                <td>
                  <i className="fas fa-check"></i>
                </td>
              </tr>
            </tbody>
          </table>

          <p className="CoverageFormat__tag CoverageFormat__margins" align="justify">
            Get a commercial offer in 2-3 working days. Services can be delivered in 15 to 20 working days. 
          </p>

          <p className="text-center">
            For more information, please{' '}
            <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
              Email Us
            </a>
          </p>

          <h4>* - References</h4>
          <ul>
            <li><a href="https://ethiopianembassy.org" target="_blank" rel="noopener noreferrer">Reference 1</a></li>
            <li><a href="https://www.britannica.com" target="_blank" rel="noopener noreferrer">Reference 2</a></li>
            <li><a href="https://freedomhouse.org/country/ethiopia/freedom-net/2020" target="_blank" rel="noopener noreferrer">Reference 3</a></li>
            <li><a href="https://datareportal.com/reports/digital-2021-ethiopia" target="_blank" rel="noopener noreferrer">Reference 4</a></li>
            <li><a href="https://absoluteethiopia.com/" target="_blank" rel="noopener noreferrer">Reference 5</a></li>
          </ul>
        </article>
      </section>

      <GetInTouch />
    </div>
  );
}

export default Ethiopia;
