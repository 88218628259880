import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Chile() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Chile - inte-QT</title>
        <meta name="title" content="Internet in Chile - inte-QT" />
        <meta name="description" content="Our Coverage in Chile" />
        <link
          rel="canonical"
          href="https://www.inte-qt.com/coverage/chile"
        />
      </Helmet>
      <img
        src="https://i.imgur.com/Z8J0VnF.png"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION*</strong> - The current population of Chile is
              19,416,438.
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Chile is a country in
              South America that borders the South Pacific Sea. Neighboring
              countries include Argentina, Bolivia, and Peru. Chile has a
              strategic location relative to sea lanes between the Atlantic and
              Pacific Oceans including the Strait of Magellan, Beagle Channel,
              and the Drake Passage.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - Spanish is the most widely
              spoken language in Chile. The variant of Spanish spoken in the
              country is Chilean Spanish. It is a dialect of Spanish that is
              significantly different from the Castilian variant of Spanish, but
              is similar in pronunciation to the Andalusian Spanish. About 14
              million Chileans speak the language. Several other indigenous and
              immigrant languages are also spoken in the country.
            </li>
            <li>
              <strong>CURRENCY</strong> - The legal currency of Chile is the
              Chilean peso. The peso was established as the monetary unit of
              Chile in 1925.
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Chile is a high-income country.
              Aside from being the most prosperous country in South America, its
              economy is also the most stable. Chile ranks among top countries
              in several metrics such as per capita income, economic freedom,
              competitiveness, and low levels of corruption. Agriculture,
              Mining, Service, Finance, and Tourism are the main industries in
              this country.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Santiago, Santiago Metropolitan</li>
                <li>Puente Alto, Santiago Metropolitan</li>
                <li>Antofagasta, Antofagasta</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>WEATHER</strong> - In Chile, a South American country
                overlooking the South Pacific Ocean, there is a wide variety of
                climates: desert but mild in the north, Mediterranean in the
                center, oceanic in the south, and cold in the Andes. The country
                has a unique shape, in fact it is very long (about 4,300
                kilometers or 2,700 miles from north to south, from 17 to 56
                degrees south latitude) and narrow, so it obviously has
                different climates and environments. Being located in the
                Southern Hemisphere, in Chile the seasons are reversed in
                comparison with North America or Europe.
              </p>
            </li>
            <li>
              <p>
                <strong>NATIONAL SPORT</strong> - Chile has had a long-time love
                affair with football. Rodeo is their national sport but it is
                safe to say that football is the most popular sport of the
                country.
              </p>
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/52e506e7641d322e1dfeabb5876ee78a/raw/7e2a98f9740929ca8884900ba2d5e96c6a5884b1/chile-162264.svg"
              alt="Chile flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            In this elongated South American country you'll discover deserts and
            glaciers, remote islands and idyllic beaches as well as a
            captivating culture. At 4,270 km (2,653 mi), Chile is the second
            longest country in the world, only narrowly being beat out by
            Brazil, which is 95 kilometers longer.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26628236.469558075!2d-106.25013511800232!3d-35.44198946059118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c5410425af2f%3A0x505e1131102b91d!2sChile!5e0!3m2!1sen!2ses!4v1653994139991!5m2!1sen!2ses"
            width="95%"
            title="Chile-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <h4 className="mt-4">Tourism*</h4>
          <p>
            In recent years, Chile has been working hard to ensure a tourism
            offer that is distinctive, inclusive, sophisticated, and developed
            under sustainability criteria according to the category assigned to
            the country, both in terms of the number of tourists that arrive
            each year and the distinctions it has received at the global level.
          </p>

          <p>
            In 2020, Chile received a little over one million international
            tourist arrivals, of which more than 786 thousand were from other
            South American countries. The second most important market for
            international inbound tourism in Chile was Europe, accounting for
            over 139 thousand visitor arrivals.
          </p>

          <p>
            Colombian was the most common nationality among international
            tourists in Chile in 2021, accounting for nearly 36 thousand
            arrivals in total.
          </p>

          <h4 className="mt-4">Main Ports / Airports</h4>

          <table className="table">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Airport</th>
                <th>IATA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Comodoro Arturo Merino Benítez International Airport</td>
                <td>SCL</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Cerro Moreno International Airport</td>
                <td>ANF</td>
              </tr>
              <tr>
                <td>3</td>
                <td>El Tepual Airport</td>
                <td>PMC</td>
              </tr>
            </tbody>
          </table>

          <h4>5 Major Ports In Chile</h4>

          <ul>
            <p>Port of Valparaíso</p>
            <p>Port of San Antonio</p>
            <p>Port of Punta Arenas</p>
            <p>Port of Iquique</p>
            <p>Port of Arica</p>
          </ul>

          <h4 className="mt-5">Typical Food</h4>
          <p>
            The national dish of Chile is Cazuela de Ave. may seem like any
            other stew, but it is not. The chicken is succulent and falls off
            the bone as you serve it. The vegetables are soft and sweet, melting
            in your mouth. All the ingredients and spices mix perfectly on your
            palate and warm your heart. This stew is delicious, easy to make and
            very inexpensive.
          </p>

          <h4 className="mt-5">Employment</h4>
          <p>
            Employment Rate in Chile increased to 54.90* percent in March from
            54.70* percent in February of 2022. In Chile, the employment rate
            measures the number of people who have a job as a percentage of the
            working-age population.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/chile"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/zVj6GNb.png"
            alt="submarine cables"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <li>Curie</li>
          <li>Fibra Optica Austral</li>
          <li>FOS QUellon-Chacabuco</li>
          <li>Prat</li>
          <li>Segunda FOS Canal de Chacao</li>
          <li>South America-1 (Sam-1)</li>
          <li>South American Crossing (SAC)</li>
          <li>South Pacific Cable System (SPSC)/Mistral</li>
        </ol>

        <h4 className="my-4">ACCESS TO LANDLINE AND MOBILE PHONE*</h4>
        <p>
          The Chilean market for fixed and mobile telephony is highly
          competitive and rapidly evolving. The mobile penetration rate is among
          the highest in South America. LTE infrastructure is extensive and 5G
          spectrum auctions which took place in February 2021 are expected to
          prompt the deployment of 5G networks by the end of the year, following
          extensive trials held by the MNOs.
        </p>

        <p>
          Chile Telecom Market has witnessed strong growth in recent years and
          is expected to have continued growth over the forecast period to 2025.
          The growth in the industry is mainly due to the increasing urban
          population with rising adoption of the mobile phones that supports 3G,
          4G, and 5G services across the country. Telecom sector is further
          expected to have strong growth over the forecast period with rising
          adoption of Internet of Things (IoT) in the sector that connects with
          wired and wireless broadband.
        </p>

        <p>
          Some of the 2G networks have already been closed, and all of the major
          2G/3G platforms are expected to be shut by the end of 2025. By 2029
          the majority of mobile connections will be on 5G. The government has
          published definitive wireline voice and broadband subscription data
          for 2017; It is expected that hundreds of million fixed broadband
          connections by 2025, versus over tens of million wireline voice
          connections, ensuring that mobile will be the principal form of
          connection.
        </p>

        <h4 className="my-4">ACCESS TO INTERNET*</h4>
        <p>
          Chile has the most modern and mature telecommunications infrastructure
          in Latin America. Since its privatization in 1980, the telecom sector
          has continued growing year after year.  Fixed broadband penetration is
          relatively high for the region, with services among the fastest and
          least expensive in Latin America.
        </p>

        <p>
          Government initiatives such as the National Fibre Optic project and
          Fibra Óptica Austral are providing high-capacity connectivity across
          the country and will further increase fixed-line broadband
          penetration. There is a strong focus on fibre broadband, with the
          number of fibre subscribers having increased 61.7% in 2020,
          year-on-year.
        </p>

        <p>
          Technological improvements have allowed operators to provide a variety
          of services via their networks, giving rise to a number of bundled
          packages at competitive prices, including access to video on demand
          services which in turn is driving the take-up of fixed-line broadband.
        </p>

        <h4 className="my-4">Our Capabilities</h4>

        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins text-center"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a
          <mark>Commercial Offer in 2 - 3 working days.</mark> Our Global Ops
          team is capable to deliver services in major cities (Valparaíso and Santiago)
          country in 3 to 4 weeks and rest of the cities in 5 - 6 weeks.
        </p>

        <p className="text-center">
          For more information, please {' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.worldometers.info/world-population/chile-population/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://globaledge.msu.edu/countries/chile"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Chile;
