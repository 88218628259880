import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';
import { app } from '../../firebase';
import { Helmet } from 'react-helmet';
import DinamicLink from '../DinamicLink/DinamicLink';
import GetInTouch from '../GetInTouch/GetInTouch';
import './BlogForm.scss';

function BlogForm() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [secondNewestBlog, setSecondNewestBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const db = getDatabase(app);

  const isImageUrl = (url) => /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i.test(url);
  const isVideoUrl = (url) => /\.(mp4|mov|wmv|avi|mkv)$/i.test(url);

  const renderHTML = (htmlString) => {
    return { __html: htmlString };
  };

  const fetchBlog = async () => {
    try {
      const blogRef = ref(db, 'blogs/' + id);
      const snapshot = await get(blogRef);
      if (snapshot.exists()) {
        setBlog(snapshot.val());
      } else {
        console.log('Blog not found');
      }
    } catch (error) {
      console.error('Error fetching blog:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSecondNewestBlog = async () => {
    try {
      const blogsRef = ref(db, 'blogs');
      const snapshot = await get(blogsRef);
      if (snapshot.exists()) {
        const blogs = snapshot.val();
        const blogList = Object.keys(blogs).map((key) => ({
          id: key,
          ...blogs[key],
        }));
        blogList.sort((a, b) => new Date(b.date) - new Date(a.date));
        if (blogList.length > 1) {
          setSecondNewestBlog(blogList[1]);
        }
      }
    } catch (error) {
      console.error('Error fetching second newest blog:', error);
    }
  };

  useEffect(() => {
    fetchBlog();
    fetchSecondNewestBlog();
    window.scroll(0, 0);
  }, [id]);

  const loadingStyles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      textAlign: 'center',
      backgroundColor: 'white',
    },
    spinner: {
      border: '4px solid #d3d3d3',
      borderTop: '4px solid #000',
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      animation: 'spin 1.5s linear infinite',
    },
    keyframes: '@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }',
    loadingText: {
      color: 'black',
      fontSize: '16px',
      marginTop: '10px',
    },
  };

  if (loading) {
    return (
      <div style={loadingStyles.container}>
        <div style={loadingStyles.spinner}></div>
        <p style={loadingStyles.loadingText}>Loading Content...</p>
      </div>
    );
  }

  if (!blog) {
    return <p>Blog not found</p>;
  }

  return (
    <div className="blog">
      <Helmet>
        <title>{blog.title} - inte-QT</title>
        <meta name="title" content={blog.title} />
        <meta name="description" content={blog.introduction} />
        <link rel="canonical" href={`https://www.inte-qt.com/blog/${id}`} />
      </Helmet>
      <h1 className="center-header center-header__title mx-auto text-center">{blog.title}</h1>
      <div className="container">
        <div className="blog__text" align="justify">
          <div className="text-end mb-5">
            <p className="blogPosts__tags">
              {blog.tags &&
                blog.tags.split(',').map((tag, index) => (
                  <span key={index} className="blue mx-2">
                    {tag.trim()}
                  </span>
                ))}
            </p>
          </div>
          <p>
            By{' '}
            <a href={blog.authorLink} className="blue">
              {blog.author}
            </a>{' '}
            on {blog.date}
          </p>

          <div dangerouslySetInnerHTML={renderHTML(blog.introduction)} />

          {blog.image1 && (
            isImageUrl(blog.image1) ? (
              <img src={blog.image1} alt={blog.title} className="blog__img blog__img-big" />
            ) : isVideoUrl(blog.image1) ? (
              <div className="blog__video">
                <video width="100%" height="400" controls>
                  <source src={blog.image1} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : null
          )}

          <div className="blog__description">
            <div dangerouslySetInnerHTML={renderHTML(blog.description)} />
          </div>

          {blog.image2 && (
            isImageUrl(blog.image2) ? (
              <img src={blog.image2} alt={blog.title} className="blog__img blog__img-second" />
            ) : isVideoUrl(blog.image2) ? (
              <div className="blog__video">
                <video width="100%" height="400" controls>
                  <source src={blog.image2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : null
          )}

          <div className="blog__conclusion">
            <div dangerouslySetInnerHTML={renderHTML(blog.conclusion)} />
          </div>
        </div>

        <div className="blogPosts__box mt-5">
          <div className="blogDemo__box mt-4">
            <DinamicLink url="/blogFile" name="Back to Blogs" />
            {secondNewestBlog && (
              <div className="blogDemo__content mt-4">
                <div className="blogDemo__imgbox">
                  
                  {secondNewestBlog.image1 && (
                    isImageUrl(secondNewestBlog.image1) ? (
                      <img
                        src={secondNewestBlog.image1}
                        alt={secondNewestBlog.title}
                        className="blogDemo__img"
                      />
                    ) : isVideoUrl(secondNewestBlog.image1) ? (
                      <div className="blogDemo__video">
                        <video width="100%" height="400" controls>
                          <source src={secondNewestBlog.image1} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    ) : null
                  )}
                </div>

                <div className="blogDemo__details">
                  <p className="blogDemo__tags">
                    {secondNewestBlog.tags &&
                      secondNewestBlog.tags.split(',').map((tag, index) => (
                        <span key={index} className="blue mx-2">
                          {tag.trim()}
                        </span>
                      ))}
                  </p>
                  <h3 className="blogDemo__title">{secondNewestBlog.title}</h3>
                  <div className="read-more-container">
                    <a href={`/blog/${secondNewestBlog.id}`} className="blue">
                      Read more
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <GetInTouch />
      </div>
    </div>
  );
}

export default BlogForm;
