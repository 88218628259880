import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Bulgaria() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Bulgaria - Coverage, Providers, and Connectivity - inte-QT</title>
        <meta
          name="description"
          content="Explore internet services in Bulgaria, including broadband speed, internet providers, submarine cables, and connectivity solutions by inte-QT."
        />
        <meta name="keywords" content="Internet in Bulgaria, Broadband, Bulgaria internet providers, submarine cables Bulgaria, mobile phone Bulgaria, internet connectivity, Bulgarian ISPs" />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/bulgaria" />
      </Helmet>
      
      <img
        src="https://i.imgur.com/QOxVANy.jpg"
        alt="Internet connectivity in Bulgaria"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - The current population of Bulgaria in 2022 is 6,844,597*.
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES</strong> - Romania, Serbia, Macedonia, Greece, and Turkey.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - <strong>Bulgarian</strong>. Approximately 85% of Bulgaria's population speaks Bulgarian, while 2.5% speak Macedonian.
            </li>
            <li>
              <strong>CURRENCY</strong> - The official currency of Bulgaria is the Bulgarian lev (BGN). Bulgaria has not yet adopted the euro.
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Key industries in Bulgaria include metallurgy, electronics, shipbuilding, petrochemicals, textiles, food and beverages, and tourism.
            </li>
            <li>
              <strong>EMPLOYMENT</strong> - The employment rate in Bulgaria averaged 47.42% from 2000 to 2021.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Sofia, 1,204,685* inhabitants</li>
                <li>Plovdiv, 334,153* inhabitants</li>
                <li>Varna, 334,870* inhabitants</li>
              </ul>
            </li>
            <li>
              <strong>WEATHER</strong> - Bulgaria features a mix of continental and Mediterranean climates with hot, dry summers and cool winters. The mean monthly temperature ranges from -1 °C to 22 °C.
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/75d86fa70faac7bd9de3a9e08741271d/raw/859a3c4f9dea3824d1793a6b479ad057fadfc0cf/bulgaria-flag.svg"
              alt="Flag of Bulgaria"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>

        <article>
          <p>
            Bulgaria, established in 681 AD, is one of Europe's oldest countries. Remarkably, Bulgaria has maintained its name throughout its history.
          </p>
          <p>
            Bulgaria ranks third in Europe for archaeological sites, after Italy and Greece, making it an important tourist destination.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3002379.306725311!2d23.3001916928295!3d42.703486968186645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a8fec1c85bf089%3A0xa01269bf4c10!2sBulgaria!5e0!3m2!1sen!2ses!4v1645524826631!5m2!1sen!2ses"
            width="95%"
            title="bulgaria-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>

          <h4 className="mt-4">Tourism in Bulgaria</h4>
          <p>
            Bulgaria has become a popular travel destination, with over 5.8 million tourists visiting for vacation or recreational purposes in 2019.
          </p>

          <h4 className="mt-4">Main Ports and Airports</h4>
          <table className="table table-striped text-center">
            <thead>
              <tr>
                <th scope="col">Rank</th>
                <th scope="col">Airport</th>
                <th scope="col">City</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Sofia Airport</td>
                <td>Sofia</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Varna Airport</td>
                <td>Varna</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Burgas Airport</td>
                <td>Burgas</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Plovdiv Airport</td>
                <td>Plovdiv</td>
              </tr>
            </tbody>
          </table>
          <p>
            Bulgaria boasts six main ports: Balchik, Burgas, Kavarna, Nessebar, Sofia, and Varna.
          </p>

          <h4 className="mt-5">National Sport in Bulgaria</h4>
          <p>
            Football is the most popular sport in Bulgaria, with many Bulgarians following the First Professional Football League and European leagues closely.
          </p>

          <h4 className="mt-5">Traditional Bulgarian Cuisine</h4>
          <p>
            Some popular Bulgarian dishes include Banitsa, Tarator, Meshana Skara, and Sarmi.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables and Connectivity in Bulgaria</h4>
        <a
          href="https://www.submarinecablemap.com/country/bulgaria"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/2Zv6hxd.png"
            alt="Submarine cables in Bulgaria"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <li>Caucasus Cable System</li>
          <li>Kafos Cable</li>
        </ol>

        <h4 className="my-4">Access to Landline and Mobile Phones in Bulgaria</h4>
        <p>
          In 2020, 79% of households in Bulgaria had access to broadband internet, marking a 4% increase from the previous year.
        </p>

        <h4 className="my-4">Internet Access and Speed in Bulgaria</h4>
        <p>
          Bulgaria ranks 20th globally for broadband internet speed and accessibility. According to SEC, the average download speed is 33.5 Mbps, with an average upload speed of 22.8 Mbps.
        </p>

        <p>
          The competitive ISP sector, including more than 600 regional ISPs, contributes to the high-speed internet available in Bulgaria.
        </p>

        <h4 className="my-4">Internet Service Providers (ISPs) in Bulgaria</h4>
        <p>
          Bulgaria has 10 major ISPs that provide broadband connectivity. inte-QT partners with local ISPs, offering services with seven infrastructure-owning partners in the country.
        </p>

        <h4 className="my-4">Our Services and Capabilities in Bulgaria</h4>
        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>SLA Support</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>Global Edge Management System (GEMS)</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>DDoS Protection</td>
              <td><i className="fas fa-check"></i></td>
            </tr>
          </tbody>
        </table>

        <p className="CoverageFormat__tag CoverageFormat__margins" align="justify">
          With inte-QT, you can expect a <mark>Commercial Offer in 2-3 working days</mark>, and services delivered within 4-6 weeks in Bulgaria.
        </p>

        <p className="text-center">
          For more details, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Inquiry from Site">
            Email Us
          </a>.
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://climateknowledgeportal.worldbank.org/country/bulgaria/climate-data-historical#:~:text=Bulgaria%20is%20characterized%20by%20two,summers%20and%20cool%20in%20winters."
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.postoffice.co.uk"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.infobusiness.bcci.bg/econ3-17-10-14.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
          <li>
            <a
              href="https://www.bbc.co.uk/languages/european_languages/languages/bulgarian.shtml"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 4
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Bulgaria;
