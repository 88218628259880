import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Vietnam() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet Services and Connectivity in Vietnam - inte-QT</title>
        <meta
          name="title"
          content="Internet Services and Connectivity in Vietnam - inte-QT"
        />
        <meta
          name="description"
          content="Explore the Internet coverage, infrastructure, and connectivity in Vietnam, including mobile services, broadband, submarine cables, and more. Learn about the key facts about Vietnam's economy, population, and tech industry."
        />
        <meta
          name="keywords"
          content="Internet in Vietnam, broadband Vietnam, mobile connectivity Vietnam, submarine cables Vietnam, Vietnam telecom market, infrastructure in Vietnam, Vietnam internet providers, Vietnam mobile networks"
        />
        <link
          rel="canonical"
          href="https://www.inte-qt.com/coverage/vietnam"
        />
      </Helmet>
      <img
        src="https://i.imgur.com/wJ1x0q1.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - In 2021, the population of Vietnam was estimated to reach 98.51 million*, indicating an increase by nearly a million people from the previous year.
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Vietnam is a long, narrow nation. It is in Southeast Asia on the eastern edge of the peninsula known as Indochina. Vietnam borders China to the north, Laos and Cambodia to the west, and shares maritime borders with Thailand through the Gulf of Thailand, and the Philippines, Indonesia, and Malaysia through the South China Sea.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - Vietnamese is the official language; English is increasingly favored as a second language. French, Chinese, Khmer and various highlander languages are also spoken. Between 85 percent and 90 percent of Vietnam's residents are ethnically Vietnamese.
            </li>
            <li>
              <strong>CURRENCY</strong> - The Vietnamese dong is the official currency of Vietnam, abbreviated to VND.
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Major Industries in Vietnam: food processing, garments, textiles, shoes, machine-building; mining, coal, steel; cement, chemical fertilizer, glass, tires, oil, mobile phones. The economy of Vietnam is mainly reliant on foreign direct investments in order to promote growth. The largest industries here are services which make up 49.75% of the GDP, industry which makes up 33.25%, and agriculture which makes up 17% of GDP.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Ho Chi Minh City</li>
                <li>Hoi Ann</li>
                <li>Hue</li>
              </ul>
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/c98561f0287840e4b9493039b62bd50d/raw/6873349def7fe48cf05c29a7bf2b31d66e8b2548/vietnam-flag.svg"
              alt="Vietnam flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            Vietnam is known for its rich history, diverse culture, and beautiful natural landscapes. Apart from its status as a key player in Southeast Asia, it is also a rapidly growing market for internet and telecom services.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7864040.465084591!2d101.41544887957!3d15.758358200014952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31157a4d736a1e5f%3A0xb03bb0c9e2fe62be!2sVietnam!5e0!3m2!1sen!2ses!4v1647338327204!5m2!1sen!2ses"
            width="95%"
            title="vietnam-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <h4 className="mt-4">Tourism</h4>
          <p>
            In 2019, Vietnam welcomed approximately 18 million* international visitors. Despite challenges like the COVID-19 pandemic, the tourism industry in Vietnam continues to be an essential driver of the economy.
          </p>
          <h4 className="mt-4">Main Ports / Airports</h4>
          <p>
            Vietnam is served by three main international airports: Noi Bai International Airport (HAN), Tan Son Nhat International Airport (SGN), and Da Nang International Airport (DAD), located in Hanoi, Ho Chi Minh City, and Da Nang respectively.
          </p>

          <p>
            The major ports in Vietnam include Hai Phong, Da Nang, Qui Nhon, and Ho Chi Minh City. Vietnam has a total of 320 ports, supporting both domestic and international trade.
          </p>
          <h4 className="mt-5">National Sport</h4>
          <p>
            Da Cau - The National Sport in Vietnam. This unique sport, often referred to as "football badminton," has been practiced in Vietnam for centuries.
          </p>
          <h4 className="mt-5">Typical Food</h4>
          <p>
            Pho is arguably the most popular food in Vietnam. Pho is a flavorful rice noodle soup served at all meals of the day, and the broth is seasoned in a distinctive way that has made it a favorite around the world.
          </p>
          <h4 className="mt-5">Weather</h4>
          <p>
            Vietnam experiences a tropical and temperate climate. The rainy season lasts from May to October in the north and south, and from September to January in the central regions, heavily influenced by the monsoon winds.
          </p>
          <h4 className="mt-5">Employment</h4>
          <p>
            Vietnam's unemployment rate remains one of the lowest globally, with a rate of just 2.2%. This low rate is driven by international companies investing in the country, particularly in its thriving telecom sector.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/vietnam"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/O2nPkbz.png"
            alt="submarine cables"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <li>Asia África Europe-1 (AAE-1)</li>

          <li>Asia-America Gateway (AAG) Cable System</li>

          <li>Asia Direct Cable (ADC)</li>

          <li>Asia Pacific Gateway (APG)</li>

          <li>SeaMeWe-3</li>

          <li>Southeast Asia-Japan Cable 2 (SJC2)</li>

          <li>Tata TGN-Intra Asia (TGN-IA)</li>
        </ol>

        <h4 className="my-4">
          ACCESS TO LANDLINE AND MOBILE PHONE ACCESS TO INTERNET*
        </h4>
        <p>
          Vietnam's telecommunication market has shown resilience, driven by the government's initiatives to support digital transformation, including smart cities and 4G/5G development.
        </p>

        <p>
          The internet penetration in Vietnam is growing rapidly. As of 2019, the number of internet users increased significantly, and the use of mobile data continues to expand, with mobile subscribers increasingly shifting to 4G and 5G networks.
        </p>

        <p>
          Foreign investment in Vietnam's telecom infrastructure is rising, with the number of mobile subscribers with 3G and 4G data connections on the rise, though the country still has room for growth in fixed-line services and broadband internet.
        </p>

        <h4 className="my-4">Internet Providers*</h4>
        <p>
          In terms of mobile network speeds, Vietnam's download speeds reached 42.46 Mbps in July 2021, with an upload speed of 19.25 Mbps. Fixed broadband speeds were slightly faster, with a download speed of 78.43 Mbps and an upload speed of 68.38 Mbps.
        </p>

        <p>
          Vietnam is served by eight major Internet providers, and inte-QT collaborates with several local partners to ensure top-tier connectivity across the country.
        </p>

        <h4 className="my-4">Our Capabilities</h4>
        <p>
          inte-QT offers broadband, stable FTTX, and IP Pools with a 24/7 support system from our NSOC team. Our services are available in the major cities of Ho Chi Minh, Hanoi, and Hai Phong.
        </p>

        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins"
          align="justify"
        >
          Our Global Business Solutions team is ready to provide a Commercial Offer within 2-4 working days, and our Global Operations team guarantees service delivery in 4 to 6 weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://tradingeconomics.com/vietnam/unemployment-rate"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.worldatlas.com/articles/languages-spoken-in-vietnam.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.statista.com/statistics/1197952/vietnam-number-of-international-visitor-arrivals/#:~:text=In%202019%2C%20Vietnam%20welcomed%20approximately,and%20its%20related%20travel%20restrictions"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
          <li>
            <a
              href="https://www.vietnam-briefing.com/news/port-infrastructure-vietnam-3-hubs-for-importers-exporters.html/#:~:text=The%20major%20ports%20in%20Vietnam,the%20country%20up%20to%20320"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 4
            </a>
          </li>
          <li>
            <a
              href="https://climateknowledgeportal.worldbank.org/country/vietnam/climate-data-historical#:~:text=Vietnam%20has%20both%20a%20tropical,regions%20from%20September%20to%20January"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 5
            </a>
          </li>
          <li>
            <a
              href="https://opengovasia.com/growth-in-vietnams-telecom-industry/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 6
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Vietnam;
