import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Japan() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>High-Speed Internet and Connectivity in Japan - inte-QT</title>
        <meta
          name="title"
          content="High-Speed Internet and Connectivity in Japan - inte-QT"
        />
        <meta
          name="description"
          content="Explore Japan's Internet infrastructure, submarine cables, and mobile connectivity with inte-QT. Learn about Japan's top cities, tourism, and industries."
        />
        <meta
          name="keywords"
          content="Internet in Japan, Japan mobile internet, submarine cables Japan, Japan internet access, Japan tourism, Japan economy, Japan infrastructure, Japan top cities, Japan technology"
        />
        <link
          rel="canonical"
          href="https://www.inte-qt.com/coverage/japan"
        />
      </Helmet>
      <img
        src="https://i.imgur.com/490TDWX.jpg"
        alt="Internet in Japan - Connectivity and Infrastructure"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION*</strong> - <strong>125.40 Million </strong>-
              In the long-term, the Japan Population is projected to trend around
              124.90 Million in 2022 and 124.50 Million in 2023, according to
              our econometric models.
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Japan is bordered by
              countries like PR China, North Korea, South Korea, the Philippines,
              Russia, and Taiwan. It is surrounded by the Sea of Okhotsk and the
              East China Sea.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - The primary language in Japan is
              Japanese.
            </li>
            <li>
              <strong>CURRENCY</strong> - The official currency in Japan is the
              Japanese Yen (JPY).
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Japan leads in industries like
              automobiles, consumer electronics, semiconductors, and iron and
              steel.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Tokyo</li>
                <li>Yokohama</li>
                <li>Osaka</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>WEATHER</strong> - Japan experiences hot and humid
                summers with snowy winters, especially in the eastern regions. Its
                subtropical climates are found in Okinawa and Amami.
              </p>
            </li>
            <li>
              <p>
                <strong>NATIONAL SPORT</strong> - Sumo wrestling is Japan's
                national sport and holds a deep cultural significance.
              </p>
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/9fb8b70032d0d7381cedd79374cbafde/raw/0ae1cb1058af9a2bd936e2e8445acd43485bbdf7/japan-162328.svg"
              alt="Flag of Japan"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            Japan is an island country comprising nearly 7,000 islands, including
            its four main islands: Hokkaido, Honshu, Kyushu, and Shikoku.
          </p>

          <p>
            Famous for its traditional arts such as tea ceremonies and calligraphy,
            Japan is also home to iconic landmarks, UNESCO World Heritage sites,
            and delicious culinary exports like sushi.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27666078.85134878!2d119.0279051098724!3d32.17169526524557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34674e0fd77f192f%3A0xf54275d47c665244!2sJapan!5e0!3m2!1sen!2ses!4v1653989295349!5m2!1sen!2ses"
            width="95%"
            title="Japan Map - Connectivity Overview"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <h4 className="mt-4">Tourism in Japan*</h4>
          <p>
            Japan has seen a significant drop in foreign visitors in 2021 due to
            global travel restrictions. However, it remains one of the top tourist
            destinations for its scenic beauty, cultural history, and advanced
            infrastructure.
          </p>

          <p>
            Tourists flock to Japan to experience its unique cultural heritage,
            historical landmarks, and diverse landscapes that include mountains,
            beaches, and cities.
          </p>

          <h4 className="mt-4">Main Ports / Airports</h4>

          <p>
            Japan is served by 5 international airports and 24 local airports.
            The airports are crucial for Japan's connectivity, facilitating both
            tourism and business operations.
          </p>

          <table className="table">
            <thead>
              <tr>
                <th>IATA</th>
                <th>Name</th>
                <th>City</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>NRT</td>
                <td>Narita International Airport</td>
                <td>Tokyo</td>
              </tr>
              <tr>
                <td>KIX</td>
                <td>Kansai International Airport</td>
                <td>Osaka</td>
              </tr>
              <tr>
                <td>FUK</td>
                <td>Fukuoka Airport</td>
                <td>Fukuoka</td>
              </tr>
              <tr>
                <td>HND</td>
                <td>Tokyo Haneda International Airport</td>
                <td>Tokyo</td>
              </tr>
            </tbody>
          </table>

          <p>5 Major Ports In Japan</p>

          <ul>
            <li>Port of Tokyo.</li>
            <li>Kobe Port.</li>
            <li>Port of Nagoya.</li>
            <li>Port of Osaka.</li>
            <li>Port of Yokohama.</li>
          </ul>

          <h4 className="mt-5">Japanese Cuisine*</h4>
          <p>
            Sushi is the most iconic dish in Japan. Contrary to common belief, it
            is not just raw fish, but a balanced combination of vinegared rice,
            raw fish, and vegetables.
          </p>

          <h4 className="mt-5">Employment in Japan*</h4>
          <p>
            Japan has a highly efficient job market, with low unemployment rates.
            The employment system is characterized by lifelong employment and a
            strong emphasis on seniority and company loyalty.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables in Japan</h4>
        <a
          href="https://www.submarinecablemap.com/country/japan"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/zKL0Av0.png"
            alt="Submarine Cables Connectivity in Japan"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <li>APCN-2</li>
              <li>Apricot</li>
              <li>Asia Direct Cable (ADC)</li>
              <li>Asia Pacific Gateway (APG)</li>
              <li>Asia Submarine-cable Express (ASE)/Cahaya</li>
              <li>Mlasya</li>
              <li>Australian-Japan Cable</li>
              <li>EAC-C2C</li>
              <li>FASTER</li>
              <li>Flag Europe-Asia (FEA)</li>
              <li>Flag North Asia Loop/Reach North Asia Loop</li>
              <li>Guan Okinama Kyushu Incheon (GOKI)</li>
              <li>Hokkaido-Sakhalin Cable System (HSCS)</li>
              <li>Japan-Guam-Australia North (JGA-N)</li>
              <li>Japan-U.S. Cable Network (JUS)</li>
            </div>
            <div className="col-md-12 col-lg-6">
              <li>Jupiter</li>
              <li>Korea-Japan Cable Network (KJCN)</li>
              <li>Miyazaki-Okinawa Cable (MOC)</li>
              <li>New Cross Pacific (NCP) Cable System</li>
              <li>Okinawa Cellular Cable</li>
              <li>Pacific Crossing-1 (PC-1)</li>
              <li>Russia-Japan Cable Network (RJCN)</li>
              <li>SeaMeWe-3</li>
              <li>Southeast Asia-Japan Cable 2 (SJC2)</li>
              <li>Southeast Asia-Japan Cable (SJC)</li>
              <li>Tata TGN-Pacific</li>
              <li>Topaz</li>
              <li>Trans-Pacific Express (TPE) Cable System</li>
              <li>Unity/EAC-Pacific</li>
            </div>
          </div>
        </ol>

        <h4 className="my-4">
          ACCESS TO LANDLINE AND MOBILE PHONE*
        </h4>
        <p>
          Japan pioneered mobile internet, and its telecom sector remains one of
          the largest and most advanced globally.
        </p>

        <h4 className="my-4">ACCESS TO INTERNET*</h4>
        <p>
          Japan boasts over 116 million internet users. Its fibre broadband
          network is among the most advanced, providing speeds of up to 10Gbps.
        </p>

        <h4 className="my-4">Our Capabilities</h4>

        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins text-center"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a
          <mark>Commercial Offer in 2 - 3 working days.</mark> Our Global Ops
          team is capable to deliver services in major cities (Tokyo and Yamaguchi)
          country in 3 to 4 weeks and rest of the cities in 5 - 6 weeks.
        </p>

        <p className="text-center">
          For more information, please
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.japan.travel/en/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.data.jma.go.jp/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://finance.yahoo.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Japan;
