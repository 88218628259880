import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, set, push, get} from 'firebase/database';


const firebaseConfig = {
  apiKey: "AIzaSyApUVe7LDGWUVDUPCPdtn9A07CI1KNtgig",
  authDomain: "inteqt-6c440.firebaseapp.com",
  databaseURL: "https://inteqt-6c440-default-rtdb.firebaseio.com",
  projectId: "inteqt-6c440",
  storageBucket: "inteqt-6c440.firebasestorage.app",
  messagingSenderId: "397786073434",
  appId: "1:397786073434:web:6015e275f51d63db114b63",
  measurementId: "G-7RL33L365D"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);


export const getUserCount = async () => {
  try {
    const usersRef = ref(database, 'users/'); 
    const snapshot = await get(usersRef);
    if (snapshot.exists()) {
      return Object.keys(snapshot.val()).length;  
    }
    return 0; 
  } catch (error) {
    console.error('Error fetching user count:', error);
    throw new Error('Could not fetch user count');
  }
};


export const signIn = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    console.log('User signed in successfully');
  } catch (error) {
    console.error('Error during sign-in:', error.message);
    throw new Error('Error during sign-in');
  }
};


export const signUp = async (email, password) => {
  try {
    
    const userCount = await getUserCount();
    if (userCount >= 12) {
      throw new Error('Sorry, the maximum number of users has been reached.');
    }

    
    await createUserWithEmailAndPassword(auth, email, password);
    console.log('User signed up successfully');
  } catch (error) {
    console.error('Error during sign-up:', error.message);
    throw new Error('Error during sign-up');
  }
};


export const onAuthStateChange = (callback) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      console.log('User signed in:', user.email);
      callback(user);
    } else {
      console.log('User signed out');
      callback(null);
    }
  });
};


export const saveBlogDataToRealtimeDB = (blogData) => {
  const blogRef = ref(database, 'blogs/');  
  const newBlogRef = push(blogRef);  

  return set(newBlogRef, {
    title: blogData.title,
    author: blogData.author,
    authorLink: blogData.authorLink,
    date: blogData.date,
    tags: blogData.tags,
    introduction: blogData.introduction,  
    image1: blogData.image1,  
    description: blogData.description,  
    image2: blogData.image2,  
    conclusion: blogData.conclusion, 
    
  }).then(() => {
    console.log('Blog data saved to Realtime Database successfully!');
  }).catch((error) => {
    console.error('Error saving blog data to Realtime Database:', error);
    throw new Error('Error saving blog data');
  });
};


export const getAllBlogsFromRealtimeDB = async () => {
  try {
    const dbRef = ref(database, 'blogs/');
    const snapshot = await get(dbRef);  
    if (snapshot.exists()) {
      const blogs = snapshot.val();
      return Object.keys(blogs).map((key) => ({ id: key, ...blogs[key] }));
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching blogs from Realtime Database:', error);
    throw new Error('Error fetching blogs');
  }
};

export { app, auth, database };
