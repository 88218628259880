import React, { useEffect } from 'react';
import '../CoverageFormat.scss';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import { Helmet } from 'react-helmet';

function Indonesia() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Comprehensive Guide to Internet Connectivity in Indonesia - inte-QT</title>
        <meta
          name="title"
          content="Comprehensive Guide to Internet Connectivity in Indonesia - inte-QT"
        />
        <meta name="description" content="Explore the internet landscape, infrastructure, and connectivity details in Indonesia." />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/indonesia" />
      </Helmet>
      <img
        src="https://i.imgur.com/2Wqitmk.jpg"
        alt="Indonesia Internet Connectivity"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - 277.846 million*
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES</strong> - Indonesia shares borders with <strong>Malaysia</strong> on Borneo island, with Papua New Guinea on Papua island, and with East Timor on Timor island. Other neighboring countries include Singapore, the Philippines, Australia, and the union territory of the Andaman and Nicobar Islands in India.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - Bahasa Indonesia
            </li>
            <li>
              <strong>CURRENCY</strong> - Indonesia Rupiah
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Major industrial sectors include{' '}
              <strong>petroleum and natural gas, textiles and apparel, mining, footwear, plywood, rubber and chemical fertilisers</strong>.
              The services sector contributes significantly to Indonesia's economy, accounting for 43 percent of GDP in 2015. Agriculture, in contrast, accounted for 14 percent.
            </li>
            <li>
              <strong>EMPLOYMENT</strong> - reported at <strong>22.65%</strong> in 2020*
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Jakarta (Jakarta Province): 10.135 million</li>
                <li>Surabaya (East Java): 2.843 million</li>
                <li>Bandung (West Java): 2.575 million</li>
              </ul>
            </li>
            <li>
              <strong>TOURISM</strong> - More than 16.1 million*
            </li>
            <li>
              <strong>WEATHER</strong> - Indonesia's climate is <strong>largely hot and humid</strong>, with rainfall primarily in low-lying areas. The mountainous regions experience cooler temperatures. Key cities like Jakarta, Ujung Padang, Medan, Padang, and Balikpapan have an average minimum temperature of 22.8°C and a high of 30.2°C.
            </li>
            <li>
              <strong>NATIONAL SPORT</strong> - <strong>Badminton</strong> is a significant part of Indonesia's cultural identity. Indonesia has consistently won gold medals in Olympic badminton since its inclusion in the 1992 Games, except in 2012.
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/1f04b22aa44a01f87349464972455de4/raw/7355c99666757619f7155a0369c0ffcb4de70f6f/indonesia-flag.svg"
              alt="indonesia flag"
              className="my-5 CoverageFormat__flag"
            />
          </div>
        </nav>
        <article>
          <p>
            Indonesia, officially known as the Republic of Indonesia (RI), is located in Southeast Asia. Positioned along the equator, it lies between the Asian and Australian continents, as well as the Pacific and Indian Oceans.
          </p>

          <p>
            Indonesia is a vast archipelago with 17,508 islands, making it the largest archipelagic country in the world. With a population exceeding 277 million, Indonesia ranks as the fourth most populous country globally and is home to the largest Muslim population, despite not being an Islamic state.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16321500.197052902!2d109.79691469855621!3d-2.890768660449413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2c4c07d7496404b7%3A0xe37b4de71badf485!2sIndonesia!5e0!3m2!1sen!2ses!4v1643709441874!5m2!1sen!2ses"
            width="95%"
            title="indonesia-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>

          <h4 className="mt-4">Main Ports / Airports</h4>

          <table className="table table-striped mb-3">
            <thead>
              <tr>
                <th scope="column">IATA</th>
                <th scope="column">Name</th>
                <th scope="column">City</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CGK</td>
                <td>Soekarno-Hatta International Airport</td>
                <td>Jakarta</td>
              </tr>
              <tr>
                <td>DPS</td>
                <td>Ngurah Rai (Bali) International Airport</td>
                <td>Denpasar-Bali Island</td>
              </tr>
              <tr>
                <td>SUB</td>
                <td>Juanda International Airport</td>
                <td>Surabaya</td>
              </tr>
              <tr>
                <td>PKU</td>
                <td>Sultan Syarif Kasim li (Simpang Tiga)</td>
                <td>Pekanbaru-Sumatra Island</td>
              </tr>
            </tbody>
          </table>
          <p>
            Indonesia's hub ports include <strong>Belawan or Kuala Tanjung, Tanjung Priok, Tanjung Perak, Kijing, Makassar, Bitung, and Sorong</strong>.
          </p>

          <h4 className="mt-5">Typical Food</h4>
          <ol>
            <li>Indonesian Satay - Skewered meat grilled over coals.</li>
            <li>Beef Rendang - A flavorful beef stew from Padang, Sumatra.</li>
            <li>Nasi Rawon - Beef stew served with rice from East Java.</li>
            <li>Sop Buntut (Oxtail Soup) - A hearty soup made from oxtail.</li>
            <li>Sweet Martabak - A delicious dessert resembling a pancake.</li>
          </ol>

          <h4 className="mt-5">Internet Connectivity in Indonesia*</h4>
          <table className="table table-striped table-responsive">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Millions</th>
                <th scope="col">Percentage</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Broadband users</td>
                <td>201.37 m</td>
                <td>72.49 %</td>
              </tr>
              <tr>
                <td>Landlines</td>
                <td>4.5 m</td>
                <td>1.65 %</td>
              </tr>
              <tr>
                <td>Mobile cellulars</td>
                <td>199.2 m</td>
                <td>71.7 %</td>
              </tr>
              <tr>
                <td>Access to internet</td>
                <td>193.9 m</td>
                <td>69.8 %</td>
              </tr>
            </tbody>
          </table>
        </article>
      </section>
      <div className="container">
        <p className="my-4">
          As of 2020, around 1.65 percent of households in Indonesia had access to a landline. However, due to the increasing prevalence of mobile phones, this figure is expected to decline in the coming years.
        </p>

        <h4 className="mt-5">Submarine Cables (Up to 70)</h4>
        <a
          href="https://www.submarinecablemap.com/country/indonesia"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/lJxEb5x.jpg"
            alt="Indonesia submarine cables"
            width="100%"
          />
        </a>
        <div className="row my-4">
          <div className="col-md-6 col-sm-12">
            <ul>
              <li>Apricot</li>
              <li>Asia Connect Cable 1 (ACC1)</li>
              <li>Australia-Singapore Cable (ASC)</li>
              <li>B2JS (Jakarta-Bangka-Batam-Singapore) Cable System</li>
              <li>BALOK</li>
              <li>Batam Dumai Melaka (BDM) Cable System</li>
              <li>Batam-Rengit Cable System (BRCS)</li>
              <li>Batam Sarawak Internet Cable System (BaSICS)</li>
              <li>Batam Singapore Cable System (BSCS)</li>
              <li>Bifrost2024</li>
              <li>DAMAI Cable System</li>
            </ul>
          </div>
          <div className="col-md-6 col-sm-12">
            <ul>
              <li>Denpasar-Waingapu Cable Systems</li>
              <li>Dumai-Melaka Cable System</li>
              <li>East-West Submarine Cable System</li>
              <li>Echo2023</li>
              <li>Hawaiki Nui2025</li>
              <li>INDIGO-West</li>
              <li>Indonesia Global Gateway (IGG) System</li>
              <li>JaKa2LaDeMa</li>
              <li>JAKABARE</li>
              <li>Jakarta-Bangka-Bintan-Batam-Singapore</li>
            </ul>
          </div>
        </div>

        <h4 className="my-4">Internet Providers*</h4>

        <p>
          Indonesia is known for its vast archipelago, with over 17,000 islands, all interconnected through a reliable communication network. The nation boasts more than 40 submarine cables, providing internet access to millions across the islands. The average internet speed in Indonesia is 17.26 mbps for downloads and 11.44 mbps for uploads.
        </p>

        <p>
          Fiber optic cables and wireless connectivity are prevalent, and the country is actively investing in infrastructure to improve nationwide connectivity.
        </p>

        <p>
          With more than 15 internet providers owning infrastructure in Indonesia, inte-QT works with 10 local partners to deliver reliable and fast internet services to enterprises across the country.
        </p>

        <h4 className="my-4">Our Capabilities</h4>
        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connect</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins"
          align="justify"
        >
          Contact us for a <mark>commercial offer within 1-2 working days</mark>. Our operations team can deliver services in major cities within 10 to 14 working days and in other regions within 4 to 6 weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>.
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://worldpopulationreview.com/countries/indonesia-population"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.aseanip.org/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://asialinkbusiness.com.au/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
          <li>
            <a
              href="https://www.marketresearch.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 4
            </a>
          </li>
        </ul>
      </div>
      <GetInTouch />
    </div>
  );
}

export default Indonesia;
