import React, { useEffect, useState } from 'react';
import './Header.scss';
import logo from './bits-bluefit-small.png';
import logowhite from './bits-white-ssm.png';
import Cookie from '../Cookie/Cookie';
import { Link } from 'react-router-dom';

const change = () => {
  
  const header = document.querySelector('.header');
  const fadeElems = document.querySelectorAll('.has-fade');
  const body = document.querySelector('body');

  if (header.classList.contains('open')) {
    // Close Hamburger Menu
    body.classList.remove('noscroll');
    header.classList.remove('open');
    fadeElems.forEach(function (element) {
      element.classList.remove('fade-in');
      element.classList.add('fade-out');
    });
  } else {
    // Open Hamburger Menu
    body.classList.add('noscroll');
    header.classList.add('open');
    fadeElems.forEach(function (element) {
      element.classList.remove('fade-out');
      element.classList.add('fade-in');
    });
  }
};

export default function Header() {
  const resolution = window.screen.width;
  const [scroll, setScroll] = useState(false);
  const path = window.location.pathname;
  const classChanger =
    path === '/' ||
    path === '/global-nsoc' ||
    path === '/services' ||
    path === '/services/dedicated-lines' ||
    path === '/services/business-broadband' ||
    path === '/coverage/austria' ||
    path === '/coverage/bulgaria' ||
    path === '/coverage/mexico' ||
    path === '/coverage/turkey' ||
    path === '/coverage/indonesia' ||
    path === '/coverage/mozambique' ||
    path === '/coverage/bermuda' ||
    path === '/coverage/nigeria' ||
    path === '/coverage/qatar' ||
    path === '/services/wireless-connect' 
      ? scroll
        ? 'header__links-link link'
        : 'header__links-link link white'
      : 'header__links-link link';

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 1);
    });
  }, []);

  return (
    <div>
      <header
        className={
          scroll
            ? 'bg-white container-fluid header fixed-top'
            : 'container-fluid header fixed-top'
        }
      >

        <div className="overlay has-fade"></div>
        <nav className="flex flex-jc-sb flex-ai-c">
          <Link className="header__logo" to="/">
            <img
              src={
                path === '/' ||
                path === '/services' ||
                path === '/global-nsoc' ||
                path === '/services/dedicated-lines' ||
                path === '/services/business-broadband' ||
                path === '/coverage/austria' ||
                path === '/coverage/bulgaria' ||
                path === '/coverage/mexico' ||
                path === '/coverage/indonesia' ||
                path === '/coverage/mozambique' ||
                path === '/coverage/bermuda' ||
                path === '/coverage/nigeria' ||
                path === '/coverage/qatar' ||
                path === '/services/wireless-connect'
                  ? resolution > 1000
                    ? scroll
                      ? logo
                      : logowhite
                    : logo
                  : logo
              }
              className="logo"
              alt="logo"
            />
          </Link>
          <span
            id="btnHamburger"
            className="header__toggle hide-for-desktop"
            onClick={change}
          >
            <span></span>
            <span></span>
            <span></span>
          </span>

          <div className="header__links hide-for-mobile">
            <Link className={classChanger} aria-current="page" to="/">
              Home
            </Link>
            <Link className={classChanger} to="/coverage">
              Coverage
            </Link>
            <Link className={classChanger} to="/services">
              Services
            </Link>
            <Link className={classChanger} to="/partner-center">
              Partner Center
            </Link>
            <Link className={classChanger} to="/cases">
              Cases
            </Link>
            <Link className={classChanger} to="/events">
              Events
            </Link>
            {/* <Link className={classChanger} to="/blog">
              Blog
            </Link> */}
            <Link className={classChanger} to="/blogFile">
              Blogs
            </Link>
            
          </div>

          <Link className="button hide-for-mobile" to="/contact">
            Contact
          </Link>
        </nav>

        <div className="header__menu has-fade">
          <a className="header__menu-links link" href="/">
            Home
          </a>
          <a className="header__menu-links link" href="/coverage">
            Coverage
          </a>
          <a className="header__menu-links link" href="/services">
            Services
          </a>
          <a className="header__menu-links link" href="/partner-center">
            Partner Center
          </a>
          <a className="header__menu-links link" href="/cases">
            Cases
          </a>
          <a className="header__menu-links link" href="/testimonials">
            Testimonials
          </a>
          <a className="header__menu-links link" href="/events">
            Events
          </a>
          {/* <a className="header__menu-links link" href="/blog">
            Blog
          </a> */}
          <a className="header__menu-links link" href="/blogFile">
          Blogs
          </a>
          <a className="header__menu-links link" href="/global-nsoc">
            Global NSOC
          </a>
          <a className="header__menu-links link" href="/about-us">
            About Us
          </a>
          <a className="header__menu-links link" href="/contact">
            Contact Us
          </a>
        </div>
      </header>
      <Cookie />
    </div>
  );
}
