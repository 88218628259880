import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { signIn, signUp, getUserCount } from '../../firebase';  // Import Firebase methods
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const AuthForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const history = useHistory();

  const handleSubmit = async () => {
    try {
      
      const userCount = await getUserCount();
      
      if (userCount >= 12) {
        setError('Please contact the admin if you want to create a new account.');
        return;
      }

      
      if (!email.endsWith('@inteqt.es')) {
        setError('Please enter a valid email address');
        return;
      }

      try {
        await signIn(email, password);  
        history.push('/blogs');
      } catch (signInError) {
        console.log('Sign-in failed, trying to sign up...');

        try {
          await signUp(email, password);  
          history.push('/blogs');
        } catch (signUpError) {
          setError(signUpError.message);  
        }
      }
    } catch (err) {
      setError(err.message);  
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
      backgroundColor: '#f4f4f4',
      padding: '20px',
    },
    title: {
      fontSize: '2rem',
      marginBottom: '20px',
      color: 'black',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: '400px',
      backgroundColor: 'white',
      padding: '30px',
      borderRadius: '10px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    },
    input: {
      padding: '12px',
      fontSize: '1rem',
      marginBottom: '15px',
      width: '100%',
      maxWidth: '300px',
      borderRadius: '5px',
      border: '1px solid #ddd',
      boxSizing: 'border-box',
      position: 'relative',
    },
    submitButton: {
      padding: '12px 20px',
      fontSize: '1rem',
      cursor: 'pointer',
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      transition: 'background-color 0.3s ease',
      width: '100%',
      maxWidth: '300px',
    },
    submitButtonHover: {
      backgroundColor: '#0056b3',
    },
    error: {
      color: 'red',
      marginTop: '15px',
    },
    eyeIcon: {
      position: 'absolute',
      right: '15px',
      top: '50%',
      transform: 'translateY(-80%)',
      cursor: 'pointer',
      color: '#007bff',
    },
    inputContainer: {
      position: 'relative',
      width: '100%',
      maxWidth: '300px',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Authentication Form</h1>
      <div style={styles.form}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email Address"
          style={styles.input}
        />
        <div style={styles.inputContainer}>
          <input
            type={isPasswordVisible ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            style={styles.input}
          />
          <div
            style={styles.eyeIcon}
            onClick={togglePasswordVisibility}
          >
            {isPasswordVisible ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
          </div>
        </div>
        <button
          onClick={handleSubmit}
          style={styles.submitButton}
          onMouseEnter={(e) => e.target.style.backgroundColor = styles.submitButtonHover.backgroundColor}
          onMouseLeave={(e) => e.target.style.backgroundColor = styles.submitButton.backgroundColor}
        >
          Submit
        </button>
        {error && <p style={styles.error}>{error}</p>}
      </div>
    </div>
  );
};

export default AuthForm;
