import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';  
import { saveBlogDataToRealtimeDB } from '../../firebase';
import ReactQuill from 'react-quill';  
import 'react-quill/dist/quill.snow.css'; 
import './Blogs.scss';

const Blogs = () => {
  const [formData, setFormData] = useState({
    title: '',
    author: '',
    authorLink: '',
    date: '',
    tags: '',
    introduction: '', 
    image1: '',  
    description: '',  
    image2: '',  
    conclusion: '',  
  });

  const [blogContent, setBlogContent] = useState(null);
  const history = useHistory();

  const wordLimit = {
    introduction: 60,
    description: 500,
    conclusion: 200,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleQuillChange = (field, value) => {
    const wordCount = countWords(value);

    if (wordCount <= wordLimit[field]) {
      setFormData({
        ...formData,
        [field]: value,
      });
    } else {
      const truncatedValue = truncateText(value, wordLimit[field]);
      setFormData({
        ...formData,
        [field]: truncatedValue,
      });
    }
  };

  const countWords = (text) => {
    const strippedText = text.replace(/(<[^>]+>)/g, " ").replace(/\s+/g, ' ').trim();
    return strippedText.split(" ").filter(Boolean).length;
  };

  const truncateText = (text, limit) => {
    const words = text.replace(/(<[^>]+>)/g, " ").split(" ").filter(Boolean);
    const truncatedWords = words.slice(0, limit);
    return truncatedWords.join(" ");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await saveBlogDataToRealtimeDB(formData);
      console.log('Blog saved to Firebase!');
      setBlogContent(formData);
      history.push('/blogFile');
    } catch (error) {
      console.error('Error saving the blog data:', error);
    }
  };

  const formStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '600px',
    margin: '20px 0',
    overflowY: 'auto',
    minHeight: '600px',
  };

  const labelStyle = {
    display: 'block',
    fontSize: '16px',
    marginBottom: '8px',
    color: 'black',
  };

  const inputStyle = {
    width: '100%',
    padding: '12px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    marginBottom: '15px',
    fontSize: '14px',
  };

  const buttonStyle = {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '12px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
  };

  const isImageUrl = (url) => {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  };

  const isVideoUrl = (url) => {
    return url.match(/\.(mp4|mov|wmv|avi)$/) != null;
  };

  useEffect(() => {
    const adjustFormHeight = () => {
      const headerHeight = document.querySelector('header')?.offsetHeight || 0;
      const footerHeight = document.querySelector('footer')?.offsetHeight || 0;
      const availableHeight = window.innerHeight - headerHeight - footerHeight;
      const formElement = document.querySelector('#blogForm');
      if (formElement) {
        formElement.style.height = `${availableHeight}px`;
      }
    };

    window.addEventListener('resize', adjustFormHeight);
    adjustFormHeight();

    return () => {
      window.removeEventListener('resize', adjustFormHeight);
    };
  }, []);

  const today = new Date().toISOString().split('T')[0];

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100vh',
      backgroundColor: '#f7f7f7',
    }}>
      <div style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 20px',
      }}>
        {!blogContent ? (
          <form onSubmit={handleSubmit} id="blogForm" style={formStyle}>
            <h2 style={{ color: 'black', textAlign: 'center' }}>Create a Blog Post</h2>

            <div className="form-group">
              <label style={labelStyle}>Blog Title</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                style={inputStyle}
              />
            </div>

            <div className="form-group">
              <label style={labelStyle}>Author Name</label>
              <input
                type="text"
                name="author"
                value={formData.author}
                onChange={handleChange}
                required
                style={inputStyle}
              />
            </div>

            <div className="form-group">
              <label style={labelStyle}>Author LinkedIn URL</label>
              <input
                type="url"
                name="authorLink"
                value={formData.authorLink}
                onChange={handleChange}
                required
                style={inputStyle}
              />
            </div>

            <div className="form-group">
              <label style={labelStyle}>Publication Date</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
                style={inputStyle}
                min={today}  
                max={today}  
              />
            </div>

            <div className="form-group">
              <label style={labelStyle}>Tags (comma separated)</label>
              <input
                type="text"
                name="tags"
                value={formData.tags}
                onChange={handleChange}
                required
                style={inputStyle}
              />
            </div>

            <div className="form-group">
              <label style={labelStyle}>Introduction</label>
              <ReactQuill
                value={formData.introduction}
                onChange={(value) => handleQuillChange('introduction', value)}
                required
                style={{ minHeight: '150px', color: 'black' }}
              />
              <p>{countWords(formData.introduction)}/{wordLimit.introduction} words</p>
            </div>

            <div className="form-group">
              <label style={labelStyle}>Image/Video 1 URL</label>
              <input
                type="url"
                name="image1" 
                value={formData.image1}
                onChange={handleChange}
                style={inputStyle}
              />
            </div>

            <div className="form-group">
              <label style={labelStyle}>Description</label>
              <ReactQuill
                value={formData.description}
                onChange={(value) => handleQuillChange('description', value)}
                style={{ minHeight: '200px', color: 'black' }}
              />
              <p>{countWords(formData.description)}/{wordLimit.description} words</p>
            </div>

            <div className="form-group">
              <label style={labelStyle}>Image/Video 2 URL</label>
              <input
                type="url"
                name="image2" 
                value={formData.image2}
                onChange={handleChange}
                style={inputStyle}
              />
            </div>

            <div className="form-group">
              <label style={labelStyle}>Conclusion</label>
              <ReactQuill
                value={formData.conclusion}
                onChange={(value) => handleQuillChange('conclusion', value)}
                style={{ minHeight: '150px', color: 'black' }}
              />
              <p>{countWords(formData.conclusion)}/{wordLimit.conclusion} words</p>
            </div>

            <button type="submit" className="create-btn" style={buttonStyle}>
              Create Blog
            </button>
          </form>
        ) : (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <h1>{blogContent.title}</h1>
            <p>{blogContent.introduction}</p>
            <p>{blogContent.author} on {blogContent.date}</p>

            {blogContent.image1 && (
              isImageUrl(blogContent.image1) ? (
                <img src={blogContent.image1} alt="Blog Media 1" style={{ maxWidth: '100%', height: 'auto' }} />
              ) : isVideoUrl(blogContent.image1) ? (
                <video width="100%" controls>
                  <source src={blogContent.image1} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : null
            )}

            <p>{blogContent.description}</p>

            {blogContent.image2 && (
              isImageUrl(blogContent.image2) ? (
                <img src={blogContent.image2} alt="Blog Media 2" style={{ maxWidth: '100%', height: 'auto' }} />
              ) : isVideoUrl(blogContent.image2) ? (
                <video width="100%" controls>
                  <source src={blogContent.image2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : null
            )}

            <h3>Conclusion:</h3>
            <p>{blogContent.conclusion}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
