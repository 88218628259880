import React, { useEffect } from 'react';
import '../CoverageFormat.scss';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import { Helmet } from 'react-helmet';

function DominicanRepublic() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Reliable Internet Services in Dominican Republic - inte-QT</title>
        <meta
          name="title"
          content="Reliable Internet Services in Dominican Republic - inte-QT"
        />
        <meta 
          name="description" 
          content="Explore our internet services and coverage in Dominican Republic. Offering business broadband, dedicated lines, and more." 
        />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/dominicanrepublic" />
      </Helmet>
      <img
        src="https://i.imgur.com/C8V8U0c.jpg"
        alt="Internet Connectivity in Dominican Republic"
        className="banner-img CoverageFormat__saturation"
      />
      <h1 className="CoverageFormat__title text-center">
        INTERNET SERVICES IN THE DOMINICAN REPUBLIC – A CONNECTIVITY HUB
      </h1>

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - 10.90 million*
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES</strong> - The island nation is bordered by <strong>Haiti</strong> in the west and shares maritime borders with Colombia, Venezuela, Aruba (Netherlands), Curacao (Netherlands), Puerto Rico (United States), and Turks and Caicos Islands (United Kingdom).
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - Spanish
            </li>
            <li>
              <strong>CURRENCY</strong> - Dominican Peso
            </li>
            <li>
              <strong>INDUSTRIES</strong> - The Dominican economy is driven by sectors like tourism, agriculture, mining, cement, tobacco, and sugar production.
            </li>
            <li>
              <strong>EMPLOYMENT RATE</strong> - 56.8 percent*
            </li>
            <li>
              <strong>TOP 3 CITIES</strong> - Santo Domingo, Santiago de los Caballeros, San Pedro de Macoris
            </li>
            <li>
              <strong>TOURISM</strong> - Over 6.4 million* visitors annually
            </li>
            <li>
              <strong>CLIMATE</strong> - Mild, tropical climate with year-round warmth.
            </li>
            <li>
              <strong>SPORT</strong> - Baseball, with Dominican players making a significant impact in Major League Baseball.
            </li>
          </ul>{' '}
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/2f7ebb0fac50d497fb9ff946898787f7/raw/f998718d488d26056d7e04e748c5bf378fba4248/dominican-flag.svg"
              alt="dominican republic flag"
              className="my-5"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            The Dominican Republic is located on the island of "La Hispaniola" in the Caribbean. Discovered by Christopher Columbus in 1492, the country has evolved into the largest economy in the Caribbean and Central America region. With its strategic location, the Dominican Republic is increasingly attracting international investors, making it a key player in the region's economic and technological growth.
          </p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1935300.8942961085!2d-71.25135073975223!3d18.669175595152634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eaf8838def1b6f5%3A0xa6020f24060df7e0!2sDominican%20Republic!5e0!3m2!1sen!2ses!4v1642674914005!5m2!1sen!2ses"
            width="95%"
            title="Dominican Republic Location Map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>

          <h4 className="mt-4">Main Ports & Airports of the Dominican Republic</h4>
          <p>
            The Dominican Republic has multiple strategic ports, including:
          </p>
          <ul>
            <li>Port of Santo Domingo. DOSDQ, Santo Domingo.</li>
            <li>Port of Rio Haina. DOHAI, Haina.</li>
            <li>Port of Puerto Plata. DOPOP, San Felipe.</li>
            <li>Port of Barahona. DOBRX, Santa Cruz de Barahona.</li>
            <li>Port of Caucedo. DOBCC, Boca Chica.</li>
            <li>Port of Samana. DOAZS, Samana Peninsula.</li>
          </ul>
        </article>
      </section>
      <div className="container">
        <p>
          With 7 international airports, Dominican Republic facilitates connectivity both domestically and internationally. Major airports include:
        </p>
        <ol>
          <li>Punta Cana International Airport, Punta Cana.</li>
          <li>Las Américas International Airport, Santo Domingo.</li>
          <li>Cibao International Airport, Santiago de los Caballeros.</li>
          <li>Gregorio Luperón International Airport, Puerto Plata.</li>
        </ol>

        <h4 className="mt-5">Dominican Republic's Traditional Cuisine</h4>
        <ul>
          <li>Sancocho, a rich stew with seven types of meat.</li>
          <li>La Bandera Dominicana, the national dish of chicken, beans, and rice.</li>
          <li>Mangú, a popular and simple dish.</li>
          <li>Dominican rice, a staple of the island’s cuisine.</li>
          <li>Mofongo, an Afro-Caribbean dish.</li>
          <li>Tostones, crispy fried plantains.</li>
          <li>Fried fish, an iconic taste of the Dominican coast.</li>
        </ul>

        <h4 className="mt-5">Dominican Republic Internet Connectivity*</h4>
        <table className="table table-striped table-responsive">
          <thead>
            <tr>
              <th scope="col">Connection Type</th>
              <th scope="col">Millions</th>
              <th scope="col">Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Broadband users</td>
              <td>1.03 m</td>
              <td>9.51 %</td>
            </tr>
            <tr>
              <td>Landlines</td>
              <td>1.03 m</td>
              <td>10.65 %</td>
            </tr>
            <tr>
              <td>Mobile cellulars</td>
              <td>8.99 m</td>
              <td>82.87 %</td>
            </tr>
            <tr>
              <td>Access to internet</td>
              <td>8.08 m</td>
              <td>79 % per 100 inhabitants</td>
            </tr>
          </tbody>
        </table>

        <h4 className="mt-5">Submarine Cables in the Dominican Republic</h4>
        <a
          href="https://www.submarinecablemap.com/country/dominican-republic"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/EvT9bqq.jpg"
            alt="submarine cables connectivity"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <li>America Movil Submarine Cable System-1 (AMX-1)</li>
          <li>Antillas 1</li>
          <li>ARCOS</li>
          <li>Boriken Submarine Cable System (BSCS) (2023)</li>
          <li>East-West</li>
          <li>Fibralink</li>
          <li>South America-1 (SAm-1)</li>
        </ol>

        <h4 className="mt-4">Internet Service Providers in Dominican Republic*</h4>

        <p>
          Despite being an island nation, the Dominican Republic offers robust internet connectivity with seven submarine cables and a growing user base. A total of 8.08 million* people have internet access.
        </p>

        <p>
          The average internet download speed in the country is 13 Mbps, and the upload speed is 7.5 Mbps. While fiber optic connections are available in 19 of the 32 provinces, wireless microwave solutions extend connectivity to more rural regions. The government continues to invest in infrastructure to improve the country's internet connectivity.
        </p>

        <p>
          The Dominican Republic has 13 internet providers, with 7 partners like inte-QT providing access to reliable services in the region.
        </p>

        <h4 className="mt-5">Our Key Internet Service Capabilities*</h4>
        <table className="table table-striped">
          <thead>
            <th scope="col">Capabilities</th>
            <th scope="col">
              <i class="fas fa-check"></i>
            </th>
          </thead>
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA (Service Level Agreements)</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS Protection</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins"
          align="justify"
        >
          Our global business solutions team can provide a <mark>Commercial Offer in 1-2 working days</mark>. Our Global Operations team delivers services in major cities within 10 to 14 working days, and in other regions, within 4 to 6 weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://tradingeconomics.com/dominican-republic/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://datatopics.worldbank.org/world-development-indicators/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.statista.com/statistics/816404/dominican-republic-number-of-tourist-arrivals/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
          <li>
            <a
              href="https://www.worlddata.info/america/dominican-republic/telecommunication.php"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 4
            </a>
          </li>
          <li>
            <a
              href="https://www.statista.com/statistics/1055456/internet-penetration-dominican-republic/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 5
            </a>
          </li>
          <li>
            <a
              href="https://www.itu.int/net4/itu-d/icteye#/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 6
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default DominicanRepublic;
