import React from 'react';

function MainCorporatePresentation() {
  return (
    <div className="container pt-5 mt-5">
      <iframe
        width="100%"
        height="600"
        src="https://www.youtube.com/embed/DJHDtplyehY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default MainCorporatePresentation;
