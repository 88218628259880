import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import BlogPosts from '../../components/BlogPosts/BlogPosts';

import GetInTouch from '../../components/GetInTouch/GetInTouch';
import TestimonialCarousel from '../../components/TestimonialCarousel/TestimonialCarousel';

function Blog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Blog Page - inte-QT</title>
        <meta name="title" content="Blog Page - inte-QT" />
        <meta
          name="description"
          content="Here you can find all our new content."
        />
        <link rel="canonical" href="https://www.inte-qt.com/blog" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <BlogPosts />
     
      <TestimonialCarousel />
      <GetInTouch />
    </>
  );
}

export default Blog;
